import React, { useContext } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import Cookies from "js-cookie";
import { message } from 'antd'
import Firebase from '../hook/firebase'
import * as firebase from "firebase/app";


function AdminLogin(pwd) {
  console.log('bonobo');

  const pushData = {
    pwd: pwd
  };

  return axios
  .post("/api/user/adminpagelogin", pushData)
  .then(res => {
    console.log('login backend success')
    console.log(res)
    if (res.data.result == true) {
      return true;
    } else {
      return false;
    }
  })
  .catch(err => { console.log(err); });

}

function DeleteOrderHistoryAction(GlobalHook, user, courseId, payment) {
  console.log('orderHistory added start')
  console.log(user.orderHistory)
  const pushData = {
    uid: user.uid,
    orderHistory: user.orderHistory
  };

  var i = 0;
  var removeIndex = 0;
  for (var item of pushData.orderHistory) {
    if (item.courseId == courseId) {
      removeIndex = i;
    }
    i++;
  }

  pushData.orderHistory.splice(removeIndex, 1)

  console.log('pushData')
  console.log(pushData)

  return axios
    .post("/api/user/updateorderhistory", pushData)
    .then(res => {
      console.log('orderHistory added success')
      console.log(res.data)
      GlobalHook.setGlobalUser(res.data.user)
    })
    .catch(err => { console.log(err); });
}


function UpdateOrderHistoryAction(GlobalHook, user, courseId, payment) {
  console.log('orderHistory added start')
  console.log(user.orderHistory)
  // console.log(payment.date.getTimezoneOffset())
  const pushData = {
    uid: user.uid,
    orderHistory: user.orderHistory
  };

  for (var item of pushData.orderHistory) {
    if (item.courseId == courseId) {
      // update existing record
      if (item.payment.status != "payment success") {
        item.payment = {
          sucess: payment.success,
          date: payment.date,
          value: payment.value,
          payerAccount: payment.payerAccount,
          status: payment.status,
        }
      }

    }
  }

  console.log('pushData')
  console.log(pushData)

  return axios
    .post("/api/user/updateorderhistory", pushData)
    .then(res => {
      console.log('orderHistory added success')
      console.log(res.data)
      GlobalHook.setGlobalUser(res.data.user)
    })
    .catch(err => { console.log(err); });
}

function UpdateUserInfoAction(GlobalHook, userUID, userName, userSchool, userSchoolStudentID ) {
  console.log('update mongodb user database info')

  const pushData = {
    uid: userUID,
    userName: userName,
    userSchool: userSchool,
    userSchoolStudentID: userSchoolStudentID,
  };

  console.log('pushData')
  console.log(pushData)

  return axios
    .post("/api/user/updateMongoUserInfo", pushData)
    .then(res => {
      console.log('user data update success')
      console.log(res.data)
      GlobalHook.setGlobalUser(res.data.user)
    })
    .catch(err => { console.log(err); });
}

function AddOrderHistoryAction(GlobalHook, uid, courseId, feeStatus) {
  console.log('orderHistory added start')
  console.log(feeStatus)

  const pushData = {
    uid: uid,
    orderHistory: GlobalHook.getGlobalUser.orderHistory
  };

  var duplicate = false;
  var allExpired = true;
  for (var item of pushData.orderHistory) {
    if (item.courseId == courseId) {
      duplicate = true;

      const startDate = new Date(item.payment.date)
      const diffTime = Math.abs(Date.now() - startDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays < 366) {
        allExpired = false;
      }

    }
  }

  if (feeStatus == 'not free') {
    console.log('miyazaki0')
    if (duplicate == false) {
      pushData.orderHistory.push({
        courseId: courseId

      })
    } else {
      if (allExpired == true) {
        pushData.orderHistory.push({
          courseId: courseId
        })
      }
    }
  } else if (feeStatus == 'free') {
    if (duplicate == false) {
      console.log('miyazaki1')
      pushData.orderHistory.push({
        courseId: courseId,
        payment: {
          status: "payment success",
          date: new Date(),
        }
      })
    } else {
      if (allExpired == true) {
        console.log('miyazaki2')
        pushData.orderHistory.push({
          courseId: courseId,
          payment: {
            status: "payment success"
          }
        })
      }
    }
  }

  console.log('pushData')
  console.log(pushData)

  if (feeStatus == 'not free') {
    axios
      .post("/api/user/updateorderhistory", pushData)
      .then(res => {
        console.log('orderHistory added success')
        console.log(res.data)
        GlobalHook.setGlobalUser(res.data.user)
      })
      .catch(err => { console.log(err); });
  }
  else {

    if (duplicate == false) {
      axios
        .post("/api/user/updateorderhistory", pushData)
        .then(res => {
          console.log('orderHistory added success')
          console.log(res.data)
          GlobalHook.setGlobalUser(res.data.user)
          CourseSubscriptionAction(GlobalHook, courseId, uid, Date.now())

        })
        .catch(err => { console.log(err); });
    } else {
        if (allExpired == true) {
          UpdateCourseSubscriptionAction(GlobalHook, courseId, uid, Date.now())
        }
    }
  }
}


function GetTokenAction(GlobalHook, uid, userName, email) {

  console.log('get token action', email)

  return axios
    .post("/api/user/getToken", { "uid": uid, "name": userName, "email": email })
    .then(res => {


      GlobalHook.setGlobalToken(res.data.token);
      Cookies.set("globalToken", res.data.token, { expires: 7 });

      GlobalHook.setGlobalUser(res.data.user);
      localStorage.setItem("globalUser", JSON.stringify(res.data.user));

      localStorage.setItem("uid", uid);
      axios.defaults.headers.common['Authorization'] = res.data.token;
      GlobalHook.setGlobalLoading(false);
      return uid
    })
    .catch(err => { console.log(err); message.error("Invalid Username or Password"); GlobalHook.setGlobalLoading(false); });
}



async function LoginAction(GlobalHook, userData, vender) {
  GlobalHook.setGlobalShowLoginModal(false);


  GlobalHook.setGlobalLoading(true);
  if (vender == "userpass") {
    try {
      await Firebase
        .auth()
        .signInWithEmailAndPassword(userData.email, userData.password).then((data) => {
          console.log('login firebase success', data.user.displayNam)
          GetTokenAction( GlobalHook, data.user.uid, data.user.displayName, data.user.email )
        })

    } catch (error) {
      GlobalHook.setGlobalLoading(false);
      message.error("Invalid Username or Password");
      console.log(error)

    }


  } 
  // else if (vender == "facebook") {
  //   let facebook = new firebase.auth.FacebookAuthProvider();
  //   facebook.addScope('email');

  //   try {
  //     await Firebase.auth().signInWithPopup(facebook).then(function (result) {
  //       var token = result.credential.accessToken;
  //       var user = result.user;
  //       GetTokenAction(GlobalHook, user.uid)


  //       // ...
  //     })

  //   } catch (error) {
  //     GlobalHook.setGlobalLoading(false);
  //     message.error("Invalid Username or Password");
  //     console.log(error)

  //   }


  // } 
  else if (vender == "google") {
    let google = new firebase.auth.GoogleAuthProvider();

    try {
      return await Firebase.auth().signInWithPopup(google).then(function (result) {
        var token = result.credential.accessToken;
        var user = result.user;
        GetTokenAction( GlobalHook, user.uid, user.displayName, user.email )

        return user.uid



        // ...
      })

    } catch (error) {
      GlobalHook.setGlobalLoading(false);
      message.success("Invalid Username or Password");
      console.log(error)

    }


  } else if (vender == "twitter") {
    // let twitter = new Firebase.auth.TwitterAuthProvider();
    // twitter.setCustomParameters({
    //   'display': 'popup'
    // });

    GlobalHook.setGlobalLoading(false);
    message.warn("twitter")

  }

}

async function SignUpAction(GlobalHook, userData) {
  GlobalHook.setGlobalShowLoginModal(false);

  GlobalHook.setGlobalLoading(true);

  try {
    await Firebase
      .auth()
      .createUserWithEmailAndPassword(userData.email, userData.password).then((data) => {

        data.user.updateProfile({
          displayName: userData.name,
          photoURL: ''
        })

        console.log('firebase signup success', data.user)
        GetTokenAction(GlobalHook, data.user.uid, data.user.displayName, data.user.email)
      })

  } catch (error) {
    console.log(error)
    message.error(error.message)
    GlobalHook.setGlobalLoading(false);

  }

}


async function ResetPassAction(GlobalHook, email) {
  GlobalHook.setGlobalShowLoginModal(false);

  GlobalHook.setGlobalLoading(true);

  try {
    await Firebase
      .auth()
      .sendPasswordResetEmail(email).then((data) => {

        console.log(email)

        GlobalHook.setGlobalLoading(false);
        message.success("password reset link has been sent to your email")
      })

  } catch (error) {
    console.log(error)
    message.error(error.message)
    GlobalHook.setGlobalLoading(false);

  }

}


// function SignUpToken(GlobalHook, uid) {

//   axios
//     .post("/api/user/register", {"uid":uid})
//     .then(res => {
//   GetTokenAction(GlobalHook, uid)
//     })
//     .catch(err =>{ console.log(err); message.error("Invalid Username or Password"); GlobalHook.setGlobalLoading(false); });
// }

// function SignUpToken(GlobalHook, userData) {
//   GlobalHook.setGlobalShowLoginModal(false);

//   GlobalHook.setGlobalLoading(true);

//   axios
//     .post("/api/user/register", userData)
//     .then(res => {
//       LoginAction(GlobalHook, userData);

//       GlobalHook.setGlobalLoading(false);
//     })
//     .catch(err => console.log(err));
// }
async function LogoutAction(GlobalHook) {

  await Firebase.auth().signOut();
  Cookies.remove("globalToken");
  localStorage.removeItem("globalUser");
  localStorage.removeItem("uid");

  GlobalHook.setGlobalToken(null);
  GlobalHook.setGlobalUser(null);


  window.location.href = "/";
}

async function UpdateCourseSubscriptionAction(GlobalHook, courseId, uid, date) {
  // GlobalHook.setGlobalLoading(true);
  // const pushData = { courseId: GlobalHook.getGlobalcourseId };
  const pushData = {
    courseId: courseId,
    uid: uid,
    date: date,
  };

  console.log('starting subsription')
  console.log(pushData)
  axios
    .post("/api/user/updatesubscription", pushData)
    .then(res => {
      // console.log(res.data)
      GlobalHook.setGlobalUser(res.data);
      localStorage.setItem("globalUser", JSON.stringify(res.data));
      // CourseSubscriptorActior(GlobalHook)
      GlobalHook.setGlobalLoading(false);
    })
    .catch(err => { GlobalHook.setGlobalLoading(false); console.log(err) });
}

async function CourseSubscriptionAction(GlobalHook, courseId, uid, date) {
  // GlobalHook.setGlobalLoading(true);
  // const pushData = { courseId: GlobalHook.getGlobalcourseId };
  const pushData = {
    courseId: courseId,
    uid: uid,
    date: date,
  };


  console.log('starting subsription')
  console.log(pushData)
  axios
    .post("/api/user/subscription", pushData)
    .then(res => {
      // console.log(res.data)

      GlobalHook.setGlobalUser(res.data); // not sure if this line should be commented, i'm commenting this out due to error in /schooladmin page when assigning a course to school user
      localStorage.setItem("globalUser", JSON.stringify(res.data));
      // CourseSubscriptorActior(GlobalHook)
      GlobalHook.setGlobalLoading(false);
    })
    .catch(err => { GlobalHook.setGlobalLoading(false); console.log(err) });
}

async function CourseSubscriptionActionFromSchoolAdmin(GlobalHook, courseId, uid, date) {
  // GlobalHook.setGlobalLoading(true);
  // const pushData = { courseId: GlobalHook.getGlobalcourseId };
  const pushData = {
    courseId: courseId,
    uid: uid,
    date: date,
  };


  console.log('starting subsription')
  console.log(pushData)
  return axios
    .post("/api/user/subscription", pushData)
    .then(res => {

      console.log('subaru', res.data)
      localStorage.setItem("globalUser", JSON.stringify(res.data));
      // CourseSubscriptorActior(GlobalHook)
      GlobalHook.setGlobalLoading(false);

      return res
    })
    .catch(err => { GlobalHook.setGlobalLoading(false); console.log(err) });
}

// function CourseSubscriptorActior(GlobalHook){
//   GlobalHook.setGlobalLoading(true);
//   const pushData = { courseId: GlobalHook.getGlobalcourseId };
//   axios
//     .post("/api/course/subscriptor", pushData)
//     .then(res => {  

//       GlobalHook.setGlobalLoading(false);
//     })
//     .catch(err => console.log(err));
//}

function LessionVisitedLogAction(GlobalHook, mediaId, startLessonTime) {
  GlobalHook.setGlobalLoading(false);
  const pushLogData = {
    courseId: GlobalHook.getGlobalcourseId,
    lessionId: GlobalHook.getGlobalLessionSelect.mediaId,
    startTime: startLessonTime,
    endTime: Date.now()
  };

  axios
    .post("/api/user/log", pushLogData)
    .then(res => {
      GlobalHook.setGlobalUser(res.data);
      localStorage.setItem("globalUser", JSON.stringify(res.data));
    })
    .catch(err => {
      console.log(err);
    });
}

function QuizLogAction(GlobalHook, QuizLogData, QuizStartTime, QuizEndTime, passResult) {
  console.log(QuizLogData)
  const pushLogData = {
    courseId: GlobalHook.getGlobalcourseId,
    lessionId: GlobalHook.getGlobalLessionSelect.mediaId,
    quizData: QuizLogData,
    quizStartTime: QuizStartTime,
    quizEndTime: QuizEndTime,
    passResult: passResult,
  };
  axios
    .post("/api/user/quizlog", pushLogData)
    .then(res => {
      GlobalHook.setGlobalUser(res.data);
      localStorage.setItem("globalUser", JSON.stringify(res.data));

    })
    .catch(err => {
      console.log(err);
    });
}
async function GetUserByIdAction(_id) {
  const pushData = {
    _id
  };
  return await axios
    .post("/api/user/getuserbyid", pushData)
    .then(res => {
      return (res.data)
    })
    .catch(err => {
      console.log(err);
    });
}

async function GetMongoUserByUidAction(uid) {
  const pushData = {
    uid
  };
  return await axios
    .post("/api/user/getuserbyuid", pushData)
    .then(res => {
      return (res.data)
    })
    .catch(err => {
      console.log(err);
    });
}

async function UpdateUserAgreementByUidAction(uid, signAgreement) {
  const pushData = {
    uid: uid,
    signAgreement: signAgreement,
  };
  return await axios
    .post("/api/user/updateuseragreementbyuid", pushData)
    .then(res => {
      return (res.data)
    })
    .catch(err => {
      console.log(err);
    });
}

function GetAllUsersFromDB() {

  return axios
    .get("/api/user/getallusersfromdb")
    .then(res => {
      console.log('fetch all users')
      console.log(res.data)
      return (res.data)
    })
    .catch(err => {
      console.log(err);
    });
}

async function GetManyUsersFromDB(listId) {

  const result = []

  const pushData = {
    listId
  };

  return await axios
    .post("/api/user/getmanyusersbyid", pushData)
    .then(res => {
      return (res.data)
    })
    .catch(err => {
      console.log(err);
    });
}

function UpdateFirebaseUser(uid, displayName) {
  const pushData = {
    uid: uid,
    displayName: displayName,
  };

  axios
    .post("/api/user/updatefirebaseuser", pushData)
    .then(res => {
      return (res.data)
    })
    .catch(err => {
      console.log(err);
    });
}

async function GetManyUsersFromFirebase(listId) {
  const result = []
  const pushData = {
    listId
  };

  return await axios
    .post("/api/user/getmanyusersfromfirebase", pushData)
    .then(res => {
      return (res.data)
    })
    .catch(err => {
      console.log(err);
    });
}


// function ResetPassAction(GlobalHook){
//   GlobalHook.setGlobalShowLoginModal(false);
//   GlobalHook.setGlobalLoading(true);

//   setTimeout(() => {
//     GlobalHook.setGlobalLoading(false);
//   }, 1000);

// }


function DeleteUserCourseDataDB(GlobalHook) {
  // GlobalHook.setGlobalLoading(true);

  const pushData = {
    courseId: GlobalHook.getGlobalcourseId,
  };

  console.log('immigration say what')
  console.log(pushData)
  return axios
    .post("/api/user/deleteTeacherCourseDataDb", pushData)
    .then(res => {
      console.log('successorial')
      // GlobalHook.setGlobalCoursePool(res.data);
      // GlobalHook.setGlobalLoading(false);
      return "success"
    })
    .catch(err => console.log(err));

}


export {
  AdminLogin,
  DeleteOrderHistoryAction,
  UpdateCourseSubscriptionAction,
  GetAllUsersFromDB,
  AddOrderHistoryAction,
  UpdateOrderHistoryAction,
  DeleteUserCourseDataDB,
  LoginAction,
  SignUpAction,
  LogoutAction,
  CourseSubscriptionAction,
  LessionVisitedLogAction,
  QuizLogAction,
  ResetPassAction,
  GetUserByIdAction,
  GetMongoUserByUidAction,
  UpdateUserAgreementByUidAction,
  GetManyUsersFromDB, GetManyUsersFromFirebase, UpdateFirebaseUser, UpdateUserInfoAction, CourseSubscriptionActionFromSchoolAdmin
};
