import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { Modal, Table, Select, Avatar, message } from "antd";
import Header from "../components/header/HeaderHome";

import { GetMongoUserByUidAction, getSchoolInfoByIdAction, FindStudentBySchoolAction, SchoolStatusChangeAction, GetStudentSchoolCourseAction, getmatchschoolcourseAction, AssignCourseToUserAction, DelCourseToUserAction, GetManyUsersFromFirebase, CourseSubscriptionActionFromSchoolAdmin } from "../actions";
import { GlobalContext } from "../hook/GlobalHook";

const { Option } = Select;

const { Column, ColumnGroup } = Table;
export default function Dashboard() {
  const GlobalHook = useContext(GlobalContext);
  const [getSelectedStudent, setSelectedStudent] = useState(null);
  const [getModalAddMyNewCourseOpenStatus, setModalAddMyNewCourseOpenStatus] = useState(false)
  const [getLocalMatchCourseSchool, setLocalMatchCourseSchool] = useState([])
  const [getLocalMatchStudentCourseSchool, setLocalMatchStudentCourseSchool] = useState([])
  const [getSelectedMongoStudent, setSelectedMongoStudent] = useState(null)

  // const [getDisplayStudentSchoolCourse, setDisplayStudentSchoolCourse] = useState([])

  const [getStudentList, setStudentList] = useState([])

  useEffect(() => {
    if (GlobalHook.getGlobalUser) {
      FindStudentBySchoolAction(
        GlobalHook,
        GlobalHook.getGlobalUser.schoolAdminId
      );
      console.log('jiffyjaff')
      console.log(GlobalHook.getGlobalUser.schoolAdminId)
      getmatchschoolcourseAction(GlobalHook, GlobalHook.getGlobalUser.schoolAdminId)

      getSchoolInfoByIdAction(GlobalHook, GlobalHook.getGlobalUser.schoolAdminId)

    }
  }, [GlobalHook.getGlobalUser]);
  // getGlobalMatchStudentCourseSchool

  // setStudentList  GlobalHook.getGlobalMatchStudentBySchool.map((item, index) => {
  //   return {
  //     key: index,
  //     Profile: "https://robohash.org/hicdoloribussunt.png?size=50x50&set=set1",
  //     Name: item.uid,
  //     Status: item.schoolApproved ? "Approved" : "Waiting",
  //     uid: item.uid,
  //     schoolApproved: item.schoolApproved
  //   }
  // }) 

  useEffect(() => {
    GlobalHook.setGlobalLoading(true);
    console.log('lorenzo')
    console.log(GlobalHook.getGlobalMatchStudentBySchool)
    if (GlobalHook.getGlobalMatchStudentBySchool.length > 0) {
      GetManyUsersFromFirebase(GlobalHook.getGlobalMatchStudentBySchool)
        .then(firebaseUserList => {
          console.log('arrazibalaga', GlobalHook.getGlobalMatchStudentBySchoola)
          console.log(firebaseUserList)

          var tempStudentList = GlobalHook.getGlobalMatchStudentBySchool.map((item, index) => {
            return {
              key: index,
              profile: "https://robohash.org/hicdoloribussunt.png?size=50x50&set=set1",
              name: item.uid,
              status: item.schoolApproved ? "Approved" : "Waiting",
              uid: item.uid,
              schoolApproved: item.schoolApproved
            }
          })
          for (var item of tempStudentList) {
            var matchStudent = firebaseUserList.filter(firebaseUser => item.uid == firebaseUser.uid)
            console.log('matchStudent')
            console.log(matchStudent)
            if (matchStudent.length == 1) {
              console.log('matcher')
              console.log(matchStudent[0].displayName)
              item.profile = matchStudent[0].photoURL;
              item.name = matchStudent[0].displayName;
            }
          }
          console.log(tempStudentList)
          setStudentList(tempStudentList)
          // setUserIdPool(data)
          // getStudentList.filter(uid ==)
          GlobalHook.setGlobalLoading(false);


        })
        .catch(error => {
          console.log(error)
        })
    }
  }, [GlobalHook.getGlobalMatchStudentBySchool, GlobalHook.getGlobalUser])

  // let getSelectedStudentCourseData = GlobalHook.getGlobalMatchStudentCourseSchool.SchoolCourseList.map((item,index)=>{
  //   return{
  //     key:index,
  //     Cover: "https://robohash.org/hicdoloribussunt.png?size=50x50&set=set5",
  //     CourseName:item
  //   }
  // })

  useEffect( () => {


  }, [GlobalHook.getGlobalSchoolInfo])


  useEffect(() => {
    if (GlobalHook.getGlobalMatchCourseSchool, GlobalHook.getGlobalUser) {

      setLocalMatchCourseSchool(GlobalHook.getGlobalMatchCourseSchool.map((item, index) => {
        return {
          key: index,
          Cover: item.courseImage,
          CourseName: item.courseName,
          courseId: item._id
        }
      }))
    }
  }, [GlobalHook.getGlobalMatchCourseSchool, GlobalHook.getGlobalUser])


  useEffect(() => {
    if (GlobalHook.getGlobalMatchStudentCourseSchool) {
      console.log('gianfranco')
      setLocalMatchStudentCourseSchool(GlobalHook.getGlobalMatchStudentCourseSchool.map((item, index) => {
        console.log('item')
        console.log(item)
        return {
          key: index,
          cover: item.cover,
          courseName: item.courseName,
          courseId: item.courseId
        }
      }))


    }
  }, [GlobalHook.getGlobalMatchStudentCourseSchool, GlobalHook.getGlobalUser])

  function RenderAddMyNewCourseModal() {
    return (
      <Modal
        visible={getModalAddMyNewCourseOpenStatus}
        title="Add School Course"
        onOk={() => setModalAddMyNewCourseOpenStatus(false)}
        onCancel={() => {
          setModalAddMyNewCourseOpenStatus(false);
        }}
        footer={[
          <div className="w-full flex justify-center">

          </div>
        ]}
      >
        <div className="flex flex-col justify-center items-center mx-auto">
          <div className="flex mb-4">
            <Table
              dataSource={getLocalMatchCourseSchool}

              className="mt-4"
            >
              <Column
                title="Cover"
                dataIndex="Cover"
                key="Cover"
                className="cursor-pointer"
                render={Cover => <Avatar size={40} src={Cover} />}
              />
              <Column
                title="CourseName"
                dataIndex="CourseName"
                key="CourseName"
                className="cursor-pointer"
              />


              <Column
                title="Action"
                key="action"
                render={(text, record) => (
                  <div
                    className="ml-2 bg-green-500 hover:bg-green-400 cursor-pointer p-2 rounded-full flex justify-center items-center text-white"
                    style={{ width: "20px", height: "20px" }}
                    onClick={() => {

                      console.log('AddingCourse')

                      var filterRes = getLocalMatchStudentCourseSchool.filter(item => item.courseId == record.courseId)

                      setModalAddMyNewCourseOpenStatus(false);

                      var timeNow = Date.now();
                      // only add new subsciption if user has never previously subscribed to course, otherwise we will only update existing data and not add new one
                      // check if assigned course is a new course that has never been subscribed by student
                      const studentPreviouslySubsribed = getSelectedMongoStudent.courseSubscription.filter( course => course.courseId == record.courseId)
                      if (studentPreviouslySubsribed[0]) {
                        
                          // AssignCourseToUserAction(GlobalHook, getSelectedStudent.uid, GlobalHook.getGlobalUser.schoolAdminId, record.courseId)
                        console.log('subz', 'Yes Previous Subscription')

                        var studentPreviouslyAssigned = []
                        for (var school of getSelectedMongoStudent.schoolCourse) {
                          // console.log('jamie', school)
                          // console.log('vardy', GlobalHook.getGlobalSchoolInfo._id)
                          // console.log('kasper',school.SchoolCourseList)
                          if (school.schoolId == GlobalHook.getGlobalSchoolInfo._id) {

                            studentPreviouslyAssigned = school.SchoolCourseList.filter( course => course._id == record.courseId)
                          }
                        }
                        console.log('kombucha', studentPreviouslyAssigned)
                        if (studentPreviouslyAssigned.length==0) {
                          AssignCourseToUserAction(GlobalHook, getSelectedStudent.uid, GlobalHook.getGlobalUser.schoolAdminId, record.courseId).then( res => {
                            console.log('pernacao', res.data)
                            // get user by uid and overwrite on to getSelectedMongoStudent
                            GetMongoUserByUidAction(getSelectedStudent.uid).then( res => {
                              console.log('mutombo', res)
                              setSelectedMongoStudent(res)
                            })
                          })
                        }

                      } else {
                        console.log('subz', 'No Previous Subscription')
                        CourseSubscriptionActionFromSchoolAdmin(GlobalHook, record.courseId, getSelectedStudent.uid, timeNow).then( res => {
                          console.log('ineacho', GlobalHook.getGlobalSchoolInfo)
                          console.log('rezzynada', res.data)
                          setSelectedMongoStudent(res.data)

                          var studentPreviouslyAssigned = []
                          for (var school of getSelectedMongoStudent.schoolCourse) {
                            console.log('jamie', school)
                            console.log('vardy', GlobalHook.getGlobalSchoolInfo._id)
                            console.log('kasper',school.SchoolCourseList)
                            if (school.schoolId == GlobalHook.getGlobalSchoolInfo._id) {

                              studentPreviouslyAssigned = school.SchoolCourseList.filter( course => course._id == record.courseId)
                            }
                          }
                          console.log('kombucha', studentPreviouslyAssigned)
                          // only assign course to student if never previously assigned by school
                          if (studentPreviouslyAssigned.length==0) {
                            AssignCourseToUserAction(GlobalHook, getSelectedStudent.uid, GlobalHook.getGlobalUser.schoolAdminId, record.courseId).then( res => {
                              // console.log('pernacao', res.data)
                              // setSelectedMongoStudent(res.data)
                            })
                          }
                        })
                      }



                    }}
                  >
                    +
                  </div>
                )}
              />
            </Table>
          </div>
        </div>
      </Modal>
    );
  }



  return (
    <>
      {console.log('amigo', getLocalMatchStudentCourseSchool)}
      {console.log('cascarino', getLocalMatchCourseSchool)}
      {console.log('fetucinada', GlobalHook.getGlobalSchoolInfo )}

      {RenderAddMyNewCourseModal()}
      <Header />
      <div className=" h-full w-full flex flex-col items-center py-4 justify-start">


        <div className="w-10/12 rounded-lg text-center text-white py-2 text-2xl font-bold mb-8 md:mb-10 bg-green-500">
          School Admin: {" "} {GlobalHook.getGlobalSchoolInfo.schoolName}
          {/* School Admin */}
        </div>
        <div className="flex flex-row flex-wrap justify-around w-full bg-white p-4">
          {GlobalHook.getGlobalSchoolInfo &&  <>
          <div className="" style={{ width: "auto" }}>
            <div className="mb-4 font-semibold text-xl">
               {"Max Quota: " + GlobalHook.getGlobalSchoolInfo.schoolMaxQuota + ", Used Quota: " + GlobalHook.getGlobalSchoolInfo.schoolUsedQuota}
            </div>
            <Table
              dataSource={getStudentList}
              onRowClick={e => {
                if (e.schoolApproved) {
                  setSelectedStudent(e);

                  GetStudentSchoolCourseAction(GlobalHook, e.uid, getLocalMatchCourseSchool)

                  console.log('bushido')
                  GetMongoUserByUidAction( e.uid).then( res => {
                    setSelectedMongoStudent(res)
                    console.log('renato', res)
                  })
        
                  console.log('tableClick')
                  console.log(e)
                } else {
                  message.warning("Not Approved")
                }

              }}
            >
              <Column
                title="Profile"
                dataIndex="profile"
                key="Profile"
                className="cursor-pointer"
                render={Profile => <Avatar size={40} src={Profile} />}
              />
              <Column
                title="Name"
                dataIndex="name"
                key="Name"
                className="cursor-pointer"
              />

              <Column
                title="Status"
                dataIndex="status"
                key="Status"
                className="cursor-pointer"
              />
                          {console.log('jamamu')}
                          {console.log(getStudentList)}

              <Column
                title="Action"
                key="action"
                render={(text, record) => (
                  <span>
                    <a
                      className="text-green-500 hover:text-green-400"
                      style={{ marginRight: 16 }}
                      onClick={() => {
                        console.log('stussy')
                        console.log(record.status)
                        
                        var usedCount = GlobalHook.getGlobalSchoolInfo.schoolUsedQuota;
                        if ((usedCount <= GlobalHook.getGlobalSchoolInfo.schoolMaxQuota) && record.status == "Waiting") {
                          usedCount++;
                          console.log('usedCount')
                          console.log(usedCount)
                          SchoolStatusChangeAction(GlobalHook, true, record.uid, usedCount)
                          console.log('seymour')
                        } else {
                          message.warning("User is already registerd to school.")
                        }

                      }}
                    >
                      ยืนยัน
                    </a>
                    <a className="text-red-500 hover:text-red-400"
                      onClick={() => {
                        console.log('stacy')
                        console.log(record.status)
                        var usedCount =  GlobalHook.getGlobalSchoolInfo.schoolUsedQuota;
                        if ( record.status == "Approved") {
                          usedCount = usedCount - 1;
                          // for (var item of getStudentList) {
                          //   if (item.status == "Approved") {
                          //     usedCount--;
                          //   }
                          // }
                          console.log('usedCount')
                          console.log(usedCount)
                          SchoolStatusChangeAction(GlobalHook, false, record.uid, usedCount)


                        } else {
                          message.error("Error Cancel")
                        }
                      }}

                    >ยกเลิก</a>
                  </span>
                )}
              />
            </Table>
          </div> </> }




          
          <div
            className=" flex flex-col p-2"
            style={{ width: "500px" }}
          >
            {getSelectedStudent && <>
              <div className="bg-yellow-400 flex justify-around items-center" style={{height:"10vh"}}>
                <Avatar
                  size={40}
                  className="cursor-pointer"
                  src={getSelectedStudent.profile}
                />
                <div>{getSelectedStudent.name}</div>
                <div style={{color:(getSelectedStudent.status)? "green": "red"}}>{getSelectedStudent.status}</div>
              </div>
              <div className="flex mt-4">
                <div> Assigned Course:</div>

                <div
                  className="ml-2 bg-orange-500 hover:bg-orange-400 cursor-pointer p-2 rounded-full flex justify-center items-center text-white"
                  style={{ width: "20px", height: "20px" }}
                  onClick={() => { setModalAddMyNewCourseOpenStatus(true) }}
                >
                  +
              </div>
              </div>
              {console.log('Natasha')}
              {console.log(getStudentList)}
              {console.log(getLocalMatchStudentCourseSchool)}
              <Table
                dataSource={getLocalMatchStudentCourseSchool}

                className="mt-4"
              >
                <Column
                  title="Cover"
                  dataIndex="cover"
                  key="Cover"
                  className="cursor-pointer"
                  render={Cover => <Avatar size={40} src={Cover} />}
                />
                <Column
                  title="CourseName"
                  dataIndex="courseName"
                  key="CourseName"
                  className="cursor-pointer"
                />


                <Column
                  title="Action"
                  key="action"
                  render={(text, record) => (
                    <span>

                      <a className="text-red-500 hover:text-red-400"
                        onClick={() => {
                          DelCourseToUserAction(GlobalHook, getSelectedStudent.uid, GlobalHook.getGlobalUser.schoolAdminId, record.courseId)
                        }}
                      >ลบ</a>
                    </span>
                  )}
                />
              </Table>
            </>
            }
          </div>
        </div>
      </div>
    </>
  );
}


