import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router";

import Header from "../components/header/HeaderTeacher";
import Banner from "../components/banner/Banner";
import MyCourse from "../components/myCourse/MyCourse";
import MySchoolCourse from "../components/schoolCourse/MySchoolCourse";
import AllSchoolCourse from "../components/schoolCourse/AllSchoolCourse";
import Footer from "../components/footer/Footer";
import { getCoursePoolAction, getSchoolIdBySlugAction, getSchoolPoolAction } from "../actions";
import { GlobalContext } from "../hook/GlobalHook";
export default function School() {
  let { schoolSlug } = useParams();
  const GlobalHook = useContext(GlobalContext);
  useEffect(() => {
    getCoursePoolAction(GlobalHook);
    GlobalHook.setGlobalSchoolSlug(schoolSlug);
    getSchoolIdBySlugAction(GlobalHook, schoolSlug)
    getSchoolPoolAction(GlobalHook);
  }, [])

  return (
    <>
      {/* <Helmet>
        <title>Studysabai:School:{}</title>
      </Helmet> */}
      <Header />

      <div className="bg-orange-300 flex flex-col py-10 items-center">
        <div className="bg-orange-500 w-3/4 rounded-lg text-center text-white py-2 text-2xl font-bold mb-2" >
          About Us
        </div>

        <div style={{ width: "70%" }}>


          <p className="mt-10">
            พวกเราทีมงานจาก <a style={{color:"#2c5282"}} href="https://www.projectlab.co.th"> Project Lab </a>  ยินดีที่จะนําเสนอแพลตฟอร์มการศึกษา Studysabai.com ซึ่งเป็นระบบการเรียนรู้ผ่านระบบ online เพื่อให้เยาชนและชาวไทยสามารถเรียนรู้ได้ทุกที่ทุกเวลา เป้าหมายของพวกเราก็คือการปฏิวัติให้การศึกษานั้นเข้าถึงง่าย และเปลี่ยนการเรียนรู้ให้เป็นกิจกรรมที่สนุก แสนสะดวก แสนสบาย ความฝันของพวกเราก็คือทําให้การเข้าถึงองค์ความรู้ และคอนเทนต์คุณภาพนั้นอยู่เพียงปลายนิ้วของเยาชนทุกคนทั่วประเทศ เพื่อให้การเรียนรู้นั้นไร้ขึดจํากัด ไม่ว่าจะเป็นขีดจํากัดเรื่องพื้นที่ นักเรียนของเราแม้อยู่ห่างไกลในต่างจังหวัด หรือบนดอยเขาห่างไกลความเจริญก็ยังสามารถเข้าถึงเนื้อหาการเรียนรู้ดีๆ จากครูเก่งๆ ที่สอนรู้เรื่อง ได้เท่าเทียมกันทุกคน
          </p>



          <p className="mt-10">
            หลักการเราต้องทําให้การศึกษานั้นเท่าเทียมกันสําหรับเยาชนทั้งประเทศ เด็กจากครอบครัวฐานะดีในหัวเมืองใหญ่ จะต้องมีโอกาศการเรียนรู้ไม่ต่างจากเด็กยากไร้ในต่างจังหวัด Education Equality เป็นหนึ่งใน Mission Statement ที่สําคัญที่สุดของพวกเรา เพื่อทําให้การเรียนรู้นั้นสามารถเข้าถึงได้ง่ายจริงๆ เรามีนโยบายที่จะจํากัดให้ราคาคอร์สเรียนใน Studysabai นั้นมีราคาที่ตํ่า ทําให้เยาชนทั่วประเทศเข้าถึงได้โดยที่ไม่ต้องหนักกระเป๋า ราคาคอร์สต่างๆให้ไม่เกิน 500 บาท โดยการตัดสินใจนี้มีพื้นฐานของหลักการว่าเราต้องทําให้การศึกษานั้นเท่าเทียมกันสําหรับเยาชนทั้งประเทศ เด็กจากครอบครัวฐานะดีในหัวเมืองใหญ่ จะต้องมีโอกาศการเรียนรู้ไม่ต่างจากเด็กยากไร้ในต่างจังหวัด Education Equality เป็นหนึ่งใน Mission Statement ที่สําคัญที่สุดของพวกเรา
         </p>

          <p className="mt-10 mb-10">
            นอกจากนี้ Studysabai นั้นยังเป็นแพลตฟอร์มให้คุณครู และผู้มีความรู้ทั้งหลาย ได้แบ่งปันทักษะความรู้ความสามารถ ในวงกว้างระดับประเทศ ไม่ว่าคุณจะเป็นใครก็ตามเราเปิดช่องทางให้คุณเข้ามาสร้างคอร์สเรียนรู้ออนไลน์ใน Studysabai โดยที่คุณสามารถ login และเริ่มสร้างคอร์สได้เลย โดยทางเรามีข้อกําหนดเพียงสามอย่างก็คือคอร์สเรียนนั้นห้ามนําเสนอข้อมูลที่เป็นเท็จโฆษณาชวนเชื่อหลอกหลวง และไม่ใช้ภาษาที่หยาบคายและเหยียดความเป็นมนุษย์ และข้อสุดท้ายต้องไม่ละเมิดกฎหมาย
          </p>

          <p className="mt-10 mb-10">
          ผู้สร้างแพลตฟอร์มการศึกษานี้คือทีมงานจาก <a style={{color:"#2c5282"}} href="https://www.projectlab.co.th"> Project Lab </a> ซึ่งนําทีมโดย ดร ภู เอี่ยมเจริญยิ่ง และ คุณพิมชนก ทวีโภค พวกเราได้อุทิศตนสําหรับการทํางานด้านการศึกษา ด้วยความฝันและเป้าหมายตั้งแต่พวกเรายังเป็นเด็กว่าพวกเราต้องการเป็นส่วนหนึ่งของการขับเคลื่อนให้การศึกษาในประเทศไทยนั้นพัฒนาไปในทิศทางที่ดีขึ้น เพราะการศึกษานั้นคือหนึ่งในพื้นฐานที่สําคัญที่สุดของชีวิตที่มีคุณค่าและมีความสุข และจะนําไปสู่สังคมที่เจริญ มีความดี สงบสุข และดีน่าอยู่ 
          </p>

          <p className="mt-10 mb-10">
          งานที่ผ่านมาของทีม <a style={{color:"#2c5282"}} href="https://www.projectlab.co.th"> Project Lab </a> นั้นประกอบไปด้วยการสอนอบรมเด็กนักเรียนเยาชน โดยเฉพาะในวิชาด้าน STEM - Science / Technology / Engineering / Mathematics และการพัฒนาเทคโนโลยีที่จะเป็นเครื่องมือที่ช่วยเหลือคุณครู และทําให้การเรียนรู้นั้นมีประสิทธิภาพสูงขึ้น 
          </p>

          <p className="mt-10 mb-10">
          หนึ่งในโครงการที่พวกเราทําสําเร็จไปแล้ว และเป็นความภาคภูมิใจของเราก็คือ project การสร้างหุ่นยนต์เพื่อการศึกษาสายพันธ์ุไทย หุ่นยนต์ <a style={{color:"#2c5282"}} href="https://www.mojobot.io"> Mojobot </a> ซึ่งเจ้าหุ่นยนต์ <a style={{color:"#2c5282"}} href="https://www.mojobot.io"> Mojobot </a> มีความพิเศษตรงที่เป็นหุ่นยนต์สําหรับเด็กเล็กที่สอนการเขียนโค้ดแบบจริงจังโดยที่ไม่ต้องใช้หน้าจอคอมพิวเตอร์ แท็บเล็ต หรือมือถือ แต่เรียนโค้ดดิ่งและอัลกอริทึ่มควบคุม การวนลูป และเซ็นเซอร์อินพุต ผ่านการเล่นแบบบอร์ดเกม และการหยิบวางชิ้นคําสั่งกายภาพด้วยการเคลื่อนไหวมือไม้ในรูปแบบของ traditonal slow play ซึ่งเหมาะอย่างยิ่งสําหรับเด็กอายุ 3-8 ขวบ
          </p>

          <p className="mt-10 mb-10">
            อยากคุยกับเราติดต่อได้ที่ 0625949255 เรายินดีอย่างยิ่งที่ได้สนทนากับคุณ
          </p>

          <img className="w-full object-cover rounded-lg rounded-b-none" style={{ width: "100%" }} src={"https://studysabaiapp.sgp1.digitaloceanspaces.com/studysabai-aboutUs-PM-team.jpg"} />


        </div>

        {/* <ScrollContainer hideScrollbars={false} vertical={false} className="flex-row overflow-x-auto flex md:flex-wrap md:overflow-hidden mt-10 w-4/5" >

        </ScrollContainer> */}
      </div>
      <Footer />
    </>
  );
}
