import React,{useContext,useEffect} from 'react';
import {BrowserRouter as Router, Route,Switch,Link} from 'react-router-dom';
import Home from '../pages/Home';
import Course from '../pages/Course';
import Dashboard from '../pages/Dashboard';
import Teacher from '../pages/Teacher';
import Studio from "../pages/Studio"
import Admin from '../pages/Admin';
import School from '../pages/School'
import SchoolAdmin from '../pages/SchoolAdmin'
import SchoolMenu from '../pages/SchoolMenu'
import Schoolinvite from '../pages/SchoolInvite'
import TermsPolicy from '../pages/TermsPolicy'
import Contact from '../pages/Contact'
import OrderHistory from '../pages/OrderHistory'
import AboutUs from '../pages/AboutUs'

import NoMatch from '../pages/NoMatch';
import PrivateRoute from './PrivateRoute'
import AdminRoute from './AdminRoute'

import { logWebVisit } from "../actions"

function RouterMain() {
  console.log('pagerefreshio')
  console.log(window.location.href)
  console.log(window.location.pathname)
  logWebVisit(window.location.href);

  return (  
    <Router>
      <Switch>
        <Route exact path="/" component={Home}/>
        <Route exact path="/course/" component={Home}/>
        <Route exact path="/course/:courseSlug" component={Course}/>
        <Route exact path="/dashboard/" component={Dashboard}/>
        <Route exact path="/teacher" component={Teacher}/>
        <Route exact path="/teacher/:courseSlug" component={Studio}/>
        <Route exact path="/school" component={SchoolMenu}/>
        <Route exact path="/school/:schoolSlug" component={School}/>
        <Route exact path="/admin" component={Admin}/>
        <Route exact path="/schooladmin" component={SchoolAdmin}/>
        <Route exact path="/schoolinvite" component={Home}/>
        <Route exact path="/schoolinvite/:schoolSlug" component={Schoolinvite}/>
        <Route exact path="/termspolicy" component={TermsPolicy}/>
        <Route exact path="/contact" component={Contact}/>
        <Route exact path="/orderhistory" component={OrderHistory}/>
        <Route exact path="/aboutus" component={AboutUs}/>

        <Route path="*" component={Home} />        
      </Switch>
    </Router>

 
  );
}

export default RouterMain;