import React from 'react'

export default function Footer() {
    return (
        <div className="bg-gray-200 flex flex-row justify-between  items-center px-4 " style={{ minHeight: "50px" }}>
            <div>Copyright © 2020 StudySabai</div>
            <div style={{right:40}}>
                <div style={{float:"left", marginRight:"10px", marginLeft:"10px"}} onClick={() => { window.location.href = `/aboutus` }} className="cursor-pointer text-gray-700 hover:text-gray-600 text-right">About Us</div>
                <div style={{float:"left", marginRight:"10px", marginLeft:"10px"}} onClick={() => { window.location.href = `/termspolicy` }} className="cursor-pointer text-gray-700 hover:text-gray-600 text-right"> {"Terms & Policy"}</div>

            </div>

        </div>
    )
}
