import React, { useState, useContext, useEffect } from 'react'
import ScrollContainer from 'react-indiana-drag-scroll'
import { Switch } from 'antd'
import { GlobalContext } from '../../hook/GlobalHook'

import CourseCard from '../courseCard/CourseCard'
import { UpdataCourseStatusAction } from '../../actions'
export default function MySchoolCourse() {

  const GlobalHook = useContext(GlobalContext)

  const [getcourseMatchPool, setcourseMatchPool] = useState([])

  const [getSchoolName, setSchoolName] = useState("")

  var myCourseMatch = [];

  // useEffect(() => {

  //   var schoolData = GlobalHook.getGlobalSchoolPool.filter(school => school.schoolSlug == GlobalHook.getGlobalSchoolSlug)

  //   console.log('schoolData', schoolData)

  //   if (schoolData.length == 0) return

  //   if (!GlobalHook.getGlobalUser) return

  //   console.log('globalUser', GlobalHook.getGlobalUser)

  //   const userSchoolData = GlobalHook.getGlobalUser.schoolCourse.find(userSchool => userSchool.schoolId == schoolData[0]._id)

  //   const userSchoolCourseList = userSchoolData.SchoolCourseList

  //   console.log('userSchoolData', userSchoolCourseList)

  //   const idsSetCourseListIds = new Set(userSchoolCourseList.map(item => item._id));

  //   const userSchoolCourseData = GlobalHook.getGlobalCoursePool.filter(course => idsSetCourseListIds.has(course._id) )

  //   console.log('userSchoolCourseData', userSchoolCourseData)

  //   console.log('globalCourseList', GlobalHook.getGlobalCoursePool)

  //   const idsSet = new Set(userSchoolCourseData.map(item => item._id));

  //   console.log('idsSet', idsSet)

  //   const matchingItems = GlobalHook.getGlobalCoursePool.filter(course => idsSet.has(course._id));
  //   const matchingItems2 = GlobalHook.getGlobalCoursePool.filter(course => course._id == '613da0a17ac27d003de48d7c');
  //   console.log('matchingItems', matchingItems2)

  // }, [GlobalHook.getGlobalSchoolSlug, GlobalHook.getGlobalSchoolPool, GlobalHook.getGlobalCoursePool, GlobalHook.getGlobalUser])


  useEffect(() => {
    if (GlobalHook.getGlobalUser && GlobalHook.getGlobalCoursePool[0]) {

      console.log('globalUser', GlobalHook.getGlobalUser)
      console.log('mySchoolCourse', GlobalHook.getGlobalUser.schoolCourse)

      GlobalHook.getGlobalCoursePool.map(allCourseList =>
        GlobalHook.getGlobalUser.schoolCourse.map(subList => {

          subList.SchoolCourseList.map((item) => {
            console.log(allCourseList)
            console.log(item)
            if (allCourseList._id == item._id) {
              myCourseMatch.push(allCourseList);
              // setcourseMatchPool(myCourseMatch);
            }
          })

        })
      );
      console.log('myCourseMatch', myCourseMatch)


      console.log('scoolio datim', GlobalHook.getGlobalCoursePool)
      console.log('school pool', GlobalHook.getGlobalSchoolPool)
      console.log('slug comparator', GlobalHook.getGlobalSchoolSlug)

      var schoolData = GlobalHook.getGlobalSchoolPool.filter(school => school.schoolSlug == GlobalHook.getGlobalSchoolSlug)

      if (schoolData.length == 0) return
      console.log('scoolio datim', schoolData)

      const schoolAllCourses = GlobalHook.getGlobalCoursePool.filter(course => course.courseSchoolId == schoolData[0]._id)
      setSchoolName(schoolData[0].schoolName)


      console.log("A111")
      console.log(schoolData)
      console.log(schoolAllCourses)
      console.log(myCourseMatch)

      setcourseMatchPool(myCourseMatch.filter(course => course.courseSchoolId == schoolData[0]._id))
    }


  }, [GlobalHook.getGlobalUser, GlobalHook.getGlobalCoursePool, GlobalHook.getGlobalSchoolPool]);

  return (
    <> {(getcourseMatchPool.length > 0) ?

      <div className="bg-orange-300 flex flex-col py-10 items-center">
        <div className="bg-orange-500 w-3/4 rounded-lg text-center text-white py-2 text-2xl font-bold mb-2" >{getSchoolName + " : My Assigned Courses"}</div>
        <ScrollContainer hideScrollbars={false} vertical={false} className="flex-row overflow-x-auto flex md:flex-wrap md:overflow-hidden mt-10 w-4/5" >
          {getcourseMatchPool.map((courseData, i) => <div

            key={i}
            className=" mb-4 mr-2 md:mr-0 hover:text-black curser-pointer no-underline md:w-1/3  lg:w-1/4 xl:w-1/4 flex justify-center"
            onClick={() => window.location.href = `/course/${courseData.courseSlug}`}
          >
            <CourseCard courseData={courseData} />
          </div>)}
        </ScrollContainer>
      </div>

      :
      <>
      </>}
    </>


  )
}
