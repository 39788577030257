import React, { useEffect, useState, useContext } from "react";
import { Menu, Dropdown, Avatar, Select, Action, Radio } from "antd";
import { Table, Tag } from 'antd';

import moment from "moment";


import { GlobalContext } from "../../hook/GlobalHook";

import SubscriptorPlate from './SubscriptorPlate'
import AreaChart from './chart/AreaChart'
import RadarChart from './chart/RadarChart'
import RadialBarChart from './chart/RadialBarChart'

import { getUsersInCourse, GetUserByIdAction, GetManyUsersFromFirebase, FetcthAllQuizInCourse } from "../../actions"
import { attempt, filter } from "lodash";

import CsvDownload from 'react-json-to-csv';


const { Option } = Select;

export default function StudioDashboardContent() {
  const GlobalHook = useContext(GlobalContext);


  const [getReviewPool, setReviewPool] = useState([])

  const [getUserIdPool, setUserIdPool] = useState([])
  const [getUserDataPool, setUserDataPool] = useState([])
  const [getUserDisplay, setUserDisplay] = useState([])

  const [getDataVisual, setDataVisual] = useState(["ความคืบหน้า"])
  const [getDataVisualSubset, setDataVisualSubset] = useState(["คะแนนทั้งหมด"])
  const [getDataVisualFormat, setDataVisualFormat] = useState(["Total"])
  const [getDataVisualTagSubset, setDataVisualTagSubset] = useState(["All Tags"])

  const [getChartData, setChartData] = useState([])
  const [getChartDataKeyList, setChartDataKeyList] = useState([])

  const [getChartKey1, setChartKey1] = useState([{ dataKey: 'correct', fill: '#68E456' }])
  const [getChartKey2, setChartKey2] = useState([{ dataKey: 'wrong', fill: '#FD6253' }])
  const [getChartKey3, setChartKey3] = useState([{ dataKey: '', fill: '' }])


  const [getSelectedStudent, setSelectedStudent] = useState(null)
  const [getSelectedStudentIndex, setSelectedStudentIndex] = useState([-2])

  const [getCourseQuizQuestionData, setCourseQuizQuestionData] = useState([])
  const [getCourseQuizTagData, setCourseQuizTagData] = useState([])

  const [getQuizAttemptListSorted, setQuizAttemptListSorted] = useState([])
  const [getQuizList, setQuizList] = useState([])
  const [getQuizFilter, setQuizFilter] = useState(["All Quiz"])

  const [getFigureTitle, setFigureTitle] = useState(["Figure Title"])



  const columns1 = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Lessons',
      dataIndex: 'completed',
      key: 'completed',
    },
    {
      title: 'Progress',
      dataIndex: 'percent',
      key: 'percent',
    }
  ];

  const columns2 = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Completed',
      dataIndex: 'completed',
      key: 'completed',
    }
  ];

  const columns3 = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Correct',
      dataIndex: 'correct',
      key: 'correct',
    },
    {
      title: 'Wrong',
      dataIndex: 'wrong',
      key: 'wrong',
    }
  ];

  const columns4 = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Score',
      dataIndex: 'score',
      key: 'score',
    },
    {
      title: 'Percent',
      dataIndex: 'percent',
      key: 'percent',
    },
    {
      title: 'Attempts',
      dataIndex: 'attempts',
      key: 'attempts',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
  ];

  const columns5 = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Score',
      dataIndex: 'score',
      key: 'score',
    },
    {
      title: 'Percent',
      dataIndex: 'percent',
      key: 'percent',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },

  ];

  const columns6 = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Score',
      dataIndex: 'score',
      key: 'score',
    },
    {
      title: 'Percent',
      dataIndex: 'percent',
      key: 'percent',
    },
  ];

  const columns7 = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Score',
      dataIndex: 'score',
      key: 'score',
    },
    {
      title: 'Percent',
      dataIndex: 'percent',
      key: 'percent',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
  ];

  const columns8 = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Score',
      dataIndex: 'score',
      key: 'score',
    },
    {
      title: 'Percent',
      dataIndex: 'percent',
      key: 'percent',
    },
    {
      title: 'Attempts',
      dataIndex: 'attempts',
      key: 'attempts',
    },
  ];

  const columnsAllStudentAllQuiz_AverageScore = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Questions',
      dataIndex: 'score',
      key: 'score',
    },
    {
      title: 'Percent',
      dataIndex: 'percent',
      key: 'percent',
    },
    {
      title: 'Quiz Completed',
      dataIndex: 'attempts',
      key: 'attempts',
    },
  ];




  const [getColumns, setColumns] = useState(columns1)

  var userIdArray = []

  useEffect(() => {
    GlobalHook.setGlobalLoading(true);
    console.log('course Id')
    console.log(GlobalHook.getGlobalcourseId)
    // console.log('start')

    FetcthAllQuizInCourse(GlobalHook.getGlobalcourseId)
      .then(courseQuizData => {
        // console.log('quizNowInFrontEnd')
        // console.log(courseQuizData)

        var courseQuizTagData = []

        for (var item of courseQuizData) {
          // console.log(item.quizTagEnglish)

          if (item.quizTagEnglish.length > 0) {
            for (var tag of item.quizTagEnglish) {
              var quizTagData = new Object()
              // console.log('tagId')
              // console.log(tag.id)
              // console.log(courseQuizTagData)
              if (courseQuizTagData.length == 0) {
                // console.log('first tag found!')
                quizTagData.tagId = tag.id;
                quizTagData.tagName = tag.name;
                // console.log(quizTagData)
                courseQuizTagData.push(quizTagData)
              } else {

                var newTag = true;
                for (var recordedTag of courseQuizTagData) {
                  if (recordedTag.tagId == tag.id) {
                    // console.log(quizTagData)
                    newTag = false;
                  }
                }

                if (newTag) {
                  // console.log('new tag found!')
                  quizTagData.tagId = tag.id;
                  quizTagData.tagName = tag.name;
                  // console.log(quizTagData)
                  courseQuizTagData.push(quizTagData)
                } else {
                  // console.log('old tag found!')
                }
              }
            }
          }
        }

        // console.log('courseQuizData')
        // console.log(courseQuizData)

        // console.log('courseQuizTagData')
        // console.log(courseQuizTagData)

        setCourseQuizQuestionData(courseQuizData)
        setCourseQuizTagData(courseQuizTagData)
      })

    getUsersInCourse(GlobalHook)
      .then(data => {
        console.log('tajmahal', data)
        setUserDataPool(data)

      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  useEffect(() => {
    GetManyUsersFromFirebase(getUserDataPool)
      .then(data => {
        // console.log('yeah')
        // console.log(data)
        setUserIdPool(data)
        GlobalHook.setGlobalLoading(false);
      })
      .catch(error => {
        console.log(error)
      })
  }, [getUserDataPool])


  useEffect(() => {
    var courseContentStructure = GlobalHook.getGlobalCourseContent.courseData[0].contentStructure

    var courseQuizData = [];
    var courseQuizSection = [];
    var i = 0;
    for (var courseSection of courseContentStructure) {
      i++;
      courseQuizData.push(courseSection.subItems.filter(subItem => subItem.type == "Quiz"))
      courseQuizSection.push("Section " + i)
    }

    var quizList = [];
    for (var section of courseQuizData) {
      for (var quiz of section) {
        quizList.push(quiz)
      }
    }

    setQuizList(quizList)

  }, [setCourseQuizTagData])



  useEffect(() => {
    if (GlobalHook.getGlobalUser && GlobalHook.getGlobalCourseReviewPool) {
      setReviewPool(GlobalHook.getGlobalCourseReviewPool)
    }
  }, [GlobalHook.getGlobalCourseReviewPool])


  useEffect(() => {
    console.log('yoga')
    console.log(getSelectedStudent)
    console.log(getQuizFilter)

    // All students selected
    if (getSelectedStudentIndex == -1) {
      if (getDataVisual == "ความคืบหน้า") {
        setFigureTitle("สรุปผลความคืบหน้าของผู้เรียนทั้งหมด")
        chooseAllStudent('All', -1, "Completed Lessons")
        setColumns(columns1)
      }
      else if (getDataVisual == "ผลแบบฝึกหัด") {
        if (getDataVisualFormat == "Completed Lessons") {
          chooseAllStudent('All', -1, "Total")
        } else {
          chooseAllStudent('All', -1, getDataVisualFormat)
        }
        //setColumns(columns3)
      }
    }
    else if (getSelectedStudent != null) {
      if (getDataVisual == "ความคืบหน้า") {
        chooseAllStudent(getSelectedStudent, getSelectedStudentIndex, "Completed Lessons")
        setColumns(columns2)
      }
      else if (getDataVisual == "ผลแบบฝึกหัด") {
        if (getDataVisualFormat == "Completed Lessons") {
          chooseStudentFunc(getSelectedStudent, getSelectedStudentIndex, "Total")
          //setColumns(columns5)
        } else {
          chooseStudentFunc(getSelectedStudent, getSelectedStudentIndex, getDataVisualFormat)
          //setColumns(columns4)
        }

      }
    }


  }, [getDataVisual, getDataVisualSubset, getDataVisualFormat, getQuizFilter, getSelectedStudent])


  function processAllStudentQuiz(user, userDataPoolList, format) {
    // console.log('formod')
    // console.log(getDataVisualFormat)
    for (user of userDataPoolList) {
      user.thisQuizResults = []
      user.thisCourseQuizList = []
    }

    // add user quiz attempts to userDataPoolList
    var userCourseQuizDataList = []
    for (user of userDataPoolList) {
      // console.log("user")
      var userCourseQuizData = user.courseSubscription.filter(course => course.courseId == GlobalHook.getGlobalcourseId)
      user.thisQuizResults.push(userCourseQuizData[0].quizLog)
    }

    // make a ranked list of quizzes in whole course
    for (var section of GlobalHook.getGlobalCourseContent.courseData[0].contentStructure) {
      // console.log('section')
      // console.log(section)
      for (var subItem of section.subItems) {
        // subItem.userAttempts=[];
        if (subItem.type == "Quiz") {
          for (user of userDataPoolList) {
            var tempItem = new Object();
            tempItem.title = subItem.title;
            tempItem.mediaId = subItem.mediaId;
            tempItem.type = subItem.type;
            tempItem.data = subItem.date;
            tempItem.userAttempts = []
            user.thisCourseQuizList.push(tempItem)
          }
        }
      }
    }

    // add user attempts to course quiz list
    for (user of userDataPoolList) {
      for (var quiz of user.thisCourseQuizList) {
        var filtered = user.thisQuizResults[0].filter((item) => item.lessionId == quiz.mediaId)
        // console.log(filtered)
        for (var item of filtered) {
          quiz.userAttempts.push(item)
        }
      }
    }

    // Prepare user & quiz data: question correct / wrong info
    var correct = 0;
    var wrong = 0;
    var correctTotal = 0;
    var wrongTotal = 0;
    var highScore;
    var lowScore;
    var tempcorrect;
    var avgScore;
    var i = 0;

    for (user of userDataPoolList) {
      correctTotal = 0;
      wrongTotal = 0;
      for (quiz of user.thisCourseQuizList) {
        correct = 0;
        wrong = 0;
        highScore = 0;
        lowScore = 999;
        for (var attempt of quiz.userAttempts) {
          tempcorrect = 0;
          i = 0;
          for (var key in attempt.quizData) { // loop questions in quiz
            i++;
            if (attempt.quizData.hasOwnProperty(key)) {
              // console.log(attempt.quizData[key].result)
              if (attempt.quizData[key].result) {
                correctTotal = correctTotal + 1;
                correct = correct + 1;
                tempcorrect++;
              } else {
                wrongTotal = wrongTotal + 1;
                wrong = wrong + 1;
              }
            }
          }
          if (tempcorrect > highScore) {
            highScore = tempcorrect;
          }
          if (tempcorrect < lowScore) {
            lowScore = tempcorrect;
          }

        }
        quiz.Qcorrect = correct;
        quiz.Qwrong = wrong;
        quiz.highScore = highScore
        quiz.lowScore = lowScore
        if (correct + wrong > 0) {
          avgScore = correct / (correct + wrong)
        } else {
          avgScore = 0;
        }

        quiz.avgScore = avgScore
        quiz.noOfQuestions = i;
      }
      user.totalQcorrect = correctTotal;
      user.totalQwrong = wrongTotal;
      user.totalQattempts = quiz.userAttempts.length;
    }

    // prepare chart data
    var chartData = [];

    // ALL STUDENT **************************************************
    if (getDataVisual == "ผลแบบฝึกหัด") {

      // ALL STUDENT - ONE QUIZ **************************************************
      if (getQuizFilter != "All Quiz") {

        // console.log('quizFilter')
        // console.log(getQuizList)

        var selectedQuiz = getQuizList.filter((item) => item.mediaId == getQuizFilter)[0]

        console.log('benchilwell', user)
        console.log(selectedQuiz)


        for (user of userDataPoolList) {
          var userQuiz = user.thisCourseQuizList.filter(quiz => selectedQuiz.mediaId == quiz.mediaId)
          console.log(userQuiz)
          if (userQuiz.length > 0) {
            userQuiz = userQuiz[0]
          }
          if (userQuiz.lowScore == 999) {
            userQuiz.lowScore = 0;
          }
          if (userQuiz) {
            if ((getDataVisualSubset == "คะแนนเฉลี่ย") || (getDataVisualSubset == "คะแนนทั้งหมด")) {
              setFigureTitle("สรุปผล - คะแนนเฉลี่ยของแบบทดสอบ " + selectedQuiz.title)
              chartData.push(
                {
                  name: user.userName + " " + user.userEmail, score: (userQuiz.avgScore * userQuiz.noOfQuestions).toFixed(1) + "/" + userQuiz.noOfQuestions, percent: (100 * userQuiz.avgScore / userQuiz.noOfQuestions).toFixed(1) + "%", attempts: userQuiz.userAttempts.length, school: user.school, schoolStudentID: user.schoolStudentID, email: user.email,
                })
            }
            if (getDataVisualSubset == "คะแนนสูงสุด") {
              setFigureTitle("สรุปผล - คะแนนสูงสุดของแบบทดสอบ " + selectedQuiz.title)
              chartData.push(
                {
                  name: user.userName + " " + user.userEmail, score: userQuiz.highScore + "/" + userQuiz.noOfQuestions, percent: (100 * userQuiz.highScore / userQuiz.noOfQuestions).toFixed(1) + "%", attempts: userQuiz.userAttempts.length, school: user.school, schoolStudentID: user.schoolStudentID, email: user.email,
                })
            }
            if (getDataVisualSubset == "คะแนนตํ่าสุด") {
              setFigureTitle("สรุปผล - คะแนนตํ่าสุดของแบบทดสอบ " + selectedQuiz.title)
              chartData.push(
                {
                  name: user.userName + " " + user.userEmail, score: userQuiz.lowScore + "/" + userQuiz.noOfQuestions, percent: (100 * userQuiz.lowScore / userQuiz.noOfQuestions).toFixed(1) + "%", attempts: userQuiz.userAttempts.length, school: user.school, schoolStudentID: user.schoolStudentID, email: user.email,
                })
            }

          }

        }

      }
      // ALL STUDENT - ALL QUIZ **************************************************
      else {
        console.log('kaihavertz')
        console.log(userDataPoolList)

        var avgScore = 0;
        var highScore;
        var lowScore;
        var attempts = 0;
        var totalQcorrect = 0;
        var totalQwrong = 0;
        var percent = 0;
        var totalQuestions = 0;

        for (user of userDataPoolList) {
          avgScore = 0;
          highScore = 0;
          lowScore = 0;
          totalQcorrect = 0;
          totalQwrong = 0;
          percent = 0;
          for (quiz of user.thisCourseQuizList) { // loop through all quizzes in course
            if (quiz.Qcorrect + quiz.Qwrong > 0) {
              avgScore = quiz.noOfQuestions * quiz.avgScore + avgScore;
              highScore = highScore + quiz.highScore;
              lowScore = lowScore + quiz.lowScore;
              totalQuestions = totalQuestions + quiz.noOfQuestions;
              attempts++;
            }
          }
          percent = (percent / attempts).toFixed(1)
          avgScore = avgScore.toFixed(1)
          console.log('hansiflick')
          console.log(avgScore)
          if ((getDataVisualSubset == "คะแนนเฉลี่ย") || (getDataVisualSubset == "คะแนนทั้งหมด")) {
            setFigureTitle("สรุปผล - คะแนนเฉลี่ยของทุก Quiz ในบททดสอบ")
            chartData.push(
              {
                name: user.userName + " " + user.userEmail, score: avgScore + "/" + totalQuestions, percent: (100 * avgScore / totalQuestions).toFixed(1) + "%", attempts: attempts + "/" + user.thisCourseQuizList.length, school: user.school, schoolStudentID: user.schoolStudentID, email: user.email,
              })
          }
          if (getDataVisualSubset == "คะแนนสูงสุด") {
            setFigureTitle("สรุปผล - คะแนนสูงสุดของทุก Quiz ในบททดสอบ")
            chartData.push(
              {
                name: user.userName + " " + user.userEmail, score: highScore + "/" + totalQuestions, percent: (100 * highScore / totalQuestions).toFixed(1) + "%", attempts: attempts + "/" + user.thisCourseQuizList.length, school: user.school, schoolStudentID: user.schoolStudentID, email: user.email,
              })
          }
          if (getDataVisualSubset == "คะแนนตํ่าสุด") {
            setFigureTitle("สรุปผล - คะแนนตํ่าสุดของทุก Quiz ในบททดสอบ")
            chartData.push(
              {
                name: user.userName + " " + user.userEmail, score: lowScore + "/" + totalQuestions, percent: (100 * lowScore / totalQuestions).toFixed(1) + "%", attempts: attempts + "/" + user.thisCourseQuizList.length, school: user.school, schoolStudentID: user.schoolStudentID, email: user.email,
              })
          }
        }

      }

      setColumns(columnsAllStudentAllQuiz_AverageScore)
      setChartData(chartData)
    }
  }







  function chooseAllStudent(chosenStudent, index, format) {

    setDataVisualFormat(format)

    var userDataPoolList = []
    for (var user of getUserIdPool) {
      var userDataPool = getUserDataPool.filter(item => item.uid == user.uid);
      userDataPool[0].userName = user.displayName;
      userDataPool[0].userEmail = user.email;
      userDataPoolList.push(userDataPool[0])
    }

    if (getDataVisual == "ผลแบบฝึกหัด") {
      processAllStudentQuiz(user, userDataPoolList, format)
    }
    else if (getDataVisual == "ความคืบหน้า") {

      for (user of userDataPoolList) {
        user.courseLog = []
        user.quizLog = []
        user.lessonList = []
      }

      // add user quiz attempts to userDataPoolList
      var userCourseQuizDataList = []
      for (user of userDataPoolList) {
        // console.log("user")
        var userCourseQuizData = user.courseSubscription.filter(course => course.courseId == GlobalHook.getGlobalcourseId)
        user.courseLog.push(userCourseQuizData[0].courseLog)
        user.quizLog.push(userCourseQuizData[0].quizLog)
      }

      var lessonList = []
      for (user of userDataPoolList) {
        for (var section of GlobalHook.getGlobalCourseContent.courseData[0].contentStructure) {
          for (var lesson of section.subItems) {
            if ((lesson.type == "Video") || (lesson.type == "Quiz") || (lesson.type == "Doc")) {
              var tempLesson = new Object()
              tempLesson.id = lesson.id;
              tempLesson.title = lesson.title;
              tempLesson.type = lesson.type;
              tempLesson.mediaId = lesson.mediaId;
              tempLesson.preview = lesson.preview;
              tempLesson.date = lesson.date;
              tempLesson.etc1 = lesson.etc1
              tempLesson.etc2 = lesson.etc2
              tempLesson.etc3 = lesson.etc3
              tempLesson.etc4 = lesson.etc4
              tempLesson.etc5 = lesson.etc5
              tempLesson.userLog = [];

              user.lessonList.push(tempLesson)
            }
          }
        }
      }

      for (user of userDataPoolList) {
        for (var lesson of user.lessonList) {
          var lessonLearnt = user.courseLog[0].filter(log => log.lessionId == lesson.mediaId)
          if (lessonLearnt.length > 0) {
            lesson.userLog = lessonLearnt;
          }
        }
      }

      if (chosenStudent == "All") {
        var chartData = [];
        var numberOfLessons = 0;
        console.log('powerranger')
        console.log(userDataPoolList)
        if (userDataPoolList.length > 0) {
          numberOfLessons = userDataPoolList[0].lessonList.length;
        }
        var lessonsCompleted = 0;
        for (user of userDataPoolList) {

          lessonsCompleted = 0;
          for (var lesson of user.lessonList) {

            var quizAttempts = user.quizLog[0].filter(quiz => quiz.lessionId == lesson.mediaId)
            if (lesson.userLog.length > 0) {
              lessonsCompleted++;
            }
            else if (quizAttempts.length > 0) {
              for (var attempt of quizAttempts) {
                if (attempt.passResult) {
                  lessonsCompleted++;
                  break;
                }
              }
            }
          }
          user.lessonsCompleted = lessonsCompleted;
          user.numberOfLessonsInCourse = numberOfLessons;

          if (format == "Completed Lessons") {
            chartData.push(
              {
                name: user.userName, completed: lessonsCompleted + "/" + numberOfLessons, percent: (100 * lessonsCompleted / numberOfLessons).toFixed(1) + "%", school: user.school, schoolStudentID: user.schoolStudentID, email: user.email,
              })
          }
        }
      } else { // specific user

        setFigureTitle("ความคืบหน้าในแต่ละบทเรียน")
        user = userDataPoolList.filter(user => user.uid == chosenStudent.uid)

        var completed = 0;
        var remaining = 0;
        var chartData = []
        console.log('nononega', user)

        if (user.length > 0) {
          for (lesson of user[0].lessonList) {

            var quizAttempts = user[0].quizLog[0].filter(quiz => quiz.lessionId == lesson.mediaId)
            console.log('.....')
            console.log(quizAttempts)


            if (lesson.userLog.length > 0) {
              completed = "PASS";
              remaining = 0;
            }
            else if (quizAttempts.length > 0) {
              completed = "FAIL"
              for (var attempt of quizAttempts) {
                console.log('attempt')
                console.log(attempt)
                if (attempt.passResult) {
                  completed = "PASS"
                }
              }
            }
            else {
              completed = "-"
            }
            // this is data of one user, therefore no need to include student's name, email, school and schoolStudentID *** BUT WE WILL DO IT ANYWAY
            chartData.push(
              {
                name: lesson.title, completed: completed, percent: remaining, school: user[0].school, schoolStudentID: user[0].schoolStudentID, email: user[0].email,

              })
            // }          
          }
        }

      }

      setChartData(chartData)

      setChartKey1([{ dataKey: 'completed', fill: '#68E456' }])
      setChartKey2([{ dataKey: 'remaining', fill: '#FD6253' }])


    }
  }


  function chooseStudentFunc(item, index, format) {
    // this is data of one user, therefore no need to include student's name, email, school and schoolStudentID

    // setSelectedStudent(item)
    // setSelectedStudentIndex(index)
    setDataVisualFormat(format)

    if (getDataVisual == "ผลแบบฝึกหัด") {

      var selectedUserData = getUserDataPool.filter(getUserDataPool => getUserDataPool.uid == item.uid);
      selectedUserData = selectedUserData[0]

      var userQuizData = selectedUserData.courseSubscription;
      userQuizData = userQuizData.filter(userCourse => userCourse.courseId == GlobalHook.getGlobalcourseId)
      userQuizData = userQuizData[0].quizLog


      const quizAttemptList = []
      for (var quizAttempt of userQuizData) {
        quizAttempt.Qcorrect = 0;
        quizAttempt.Qwrong = 0;
        for (var questionKey in quizAttempt.quizData) {
          if (quizAttempt.quizData[questionKey].result) {
            quizAttempt.Qcorrect++;
          } else {
            quizAttempt.Qwrong++;
          }
        }
        quizAttemptList.push(quizAttempt)
      }

      var quizList = getQuizList;

      var i = 0;
      var quizAttemptListSorted = []
      for (var quiz of quizList) {
        quizAttemptListSorted[i] = quizAttemptList.filter(quizAttempt => quizAttempt.lessionId == quiz.mediaId)
        i++;
      }

      var total_correct;
      var total_wrong;
      var high_score;
      var low_score;
      var number_of_questions;
      var attemptCounter;

      i = 0;
      for (var quizQuestion of quizAttemptListSorted) {
        j = 0
        total_correct = 0;
        total_wrong = 0;
        high_score = 0;
        low_score = 999;
        number_of_questions = 0;
        attemptCounter = 0
        for (var attempts of quizQuestion) {
          attemptCounter++;
          total_correct = attempts.Qcorrect + total_correct;
          total_wrong = attempts.Qwrong + total_wrong;

          if (attempts.Qcorrect > high_score) {
            high_score = attempts.Qcorrect
          }
          if (attempts.Qcorrect < low_score) {
            low_score = attempts.Qcorrect
          }
          j++;
          number_of_questions = Object.keys(attempts.quizData).length;
        }
        if (low_score == "999") {
          low_score = 0;
        }

        quizQuestion.numberOfQuestions = number_of_questions;
        quizQuestion.highScore = high_score;
        quizQuestion.lowScore = low_score;
        quizQuestion.totalCorrect = total_correct;
        quizQuestion.totalWrong = total_wrong;
        quizQuestion.avgQcorrect = total_correct / j;
        quizQuestion.avgQwrong = total_wrong / j;
        quizQuestion.mediaId = getQuizList[i].mediaId;
        quizQuestion.numberOfAttempts = attemptCounter;
        i++
      }

      // prepare chart data array
      const chartData = [];
      var correct = 0;
      var wrong = 0;


      if (getQuizFilter != "All Quiz") {

        // preprocessing Data for Chosen Quiz (not for all Quizzes!)
        quizList = getQuizList.filter((item) => item.mediaId == getQuizFilter)

        var quizQuestionsList = getCourseQuizQuestionData.filter((item) => item.mediaId == quizList[0].mediaId)

        var userAttempts = quizAttemptListSorted.filter((item) => item.mediaId == quizList[0].mediaId)

        var userAttemptedQuestionList = []


        for (var quizAttempt of userAttempts[0]) {
          for (var key in quizAttempt.quizData) {
            if (quizAttempt.quizData.hasOwnProperty(key)) {
              quizAttempt.quizData[key].questionId = key;
              userAttemptedQuestionList.push(quizAttempt.quizData[key])
            }
          }
        }


        for (question of quizQuestionsList) {
          var attemptsAtQuestion = userAttemptedQuestionList.filter((item) => item.questionId == question.questionId)

          i = 0;
          correct = 0;
          wrong = 0;
          question.attemptList = []
          for (var attempt of attemptsAtQuestion) {
            question.attemptList.push(attempt)
            if (attempt.result) {
              correct = correct + 1;
            } else {
              wrong = wrong + 1;
            }
            i++;
          }
          if (correct > 0) {
            question.highScore = 1
            if (wrong == 0) {
              question.lowScore = 1;
            }
          }
          if (wrong > 0) {
            question.lowScore = 0
            if (correct == 0) {
              question.highScore = 0;
            }
          }
          question.Qcorrect = correct;
          question.Qwrong = wrong;
          question.QcorrectAvg = correct / i;
          question.QwrongAvg = wrong / i;
          question.numberOfAttempts = i;
        }

        // SPECIFIC QUIZ ---------------------------------------------------------------------------------------------------------------------------------
        // SPECIFIC QUIZ ---------------------------------------------------------------------------------------------------------------------------------
        // SPECIFIC QUIZ ---------------------------------------------------------------------------------------------------------------------------------
        // SPECIFIC QUIZ ---------------------------------------------------------------------------------------------------------------------------------
        if (getDataVisualSubset == "คะแนนทั้งหมด") {
          setColumns(columns7)
          quizList = quizList.filter((item) => item.mediaId == getQuizFilter)
          var quizQuestionsList = getCourseQuizQuestionData.filter((item) => item.mediaId == quizList[0].mediaId)
          var userAttempts = quizAttemptListSorted.filter((item) => item.mediaId == quizList[0].mediaId)

          i = 0;
          for (var quizAttempt of userAttempts[0]) {

            console.log('tiempo', selectedUserData)

            var quizDate = new Date()
            quizDate.setTime(quizAttempt.endTime)

            // Summary of Attempt
            chartData.push(
              {
                name: "สอบครั้งที่ " + (userAttempts[0].length - i).toString() + "-สรุปผลสอบ",
                score: quizAttempt.Qcorrect + "/" + (quizAttempt.Qcorrect + quizAttempt.Qwrong),
                percent: (quizAttempt.Qcorrect / (quizAttempt.Qcorrect + quizAttempt.Qwrong) * 100).toFixed(1),
                date: quizDate.getDate() + "/" + quizDate.getMonth() + "/" + quizDate.getFullYear() + " " + quizDate.getHours() + ":" + ('0' + quizDate.getMinutes()).slice(-2),
                school: selectedUserData.school, schoolStudentID: selectedUserData.schoolStudentID, email: selectedUserData.email,
              })

            for (var key in quizAttempt.quizData) {
              if (quizAttempt.quizData.hasOwnProperty(key)) {
                if (quizAttempt.quizData[key].result) {
                  correct = 1;
                  wrong = 0;
                } else {
                  wrong = 1;
                  correct = 0;
                }
                console.log('hiho')
                console.log(quizAttempt)
                console.log(quizQuestionsList)


                question = quizQuestionsList.filter(item => item.questionId == quizAttempt.quizData[key].questionId)[0]

                chartData.push(
                  {
                    name: "สอบครั้งที่ " + (userAttempts[0].length - i).toString() + "-" + question.questionName,
                    score: correct + "/1",
                    percent: correct * 100,
                    date: quizDate.getDate() + "/" + quizDate.getMonth() + "/" + quizDate.getFullYear() + " " + quizDate.getHours() + ":" + ('0' + quizDate.getMinutes()).slice(-2),
                    school: selectedUserData.school, schoolStudentID: selectedUserData.schoolStudentID, email: selectedUserData.email,
                  })

              }
            }

            i++;
          }
        }
        else if (getDataVisualSubset == "คะแนนเฉลี่ย") {
          setColumns(columns8)
          console.log('quizQuestionsList')
          console.log(quizQuestionsList)

          for (question of quizQuestionsList) {
            score = "-"
            percent = "-"
            attempts = "-"
            if (question.numberOfAttempts != 0) {
              score = question.QcorrectAvg.toFixed(1) + " /1"
              percent = (question.QcorrectAvg * 100).toFixed(1)
              attempts = question.numberOfAttempts
            }

            chartData.push(
              {
                name: question.questionName, score: score, percent: percent, attempts: attempts, school: selectedUserData.school, schoolStudentID: selectedUserData.schoolStudentID, email: selectedUserData.email,
              })
          }
        }
        else if (getDataVisualSubset == "คะแนนสูงสุด") {
          setColumns(columns8)
          for (question of quizQuestionsList) {
            score = "-"
            percent = "-"
            attempts = "-"
            if (question.numberOfAttempts != 0) {
              score = question.highScore.toFixed(1) + " /1"
              percent = (question.highScore * 100).toFixed(1)
              attempts = question.numberOfAttempts
            }

            chartData.push(
              {
                name: question.questionName, score: score, percent: percent, attempts: attempts, school: selectedUserData.school, schoolStudentID: selectedUserData.schoolStudentID, email: selectedUserData.email,
              })
          }
        }
        else if (getDataVisualSubset == "คะแนนตํ่าสุด") {
          setColumns(columns8)
          for (question of quizQuestionsList) {
            score = "-"
            percent = "-"
            attempts = "-"
            if (question.numberOfAttempts != 0) {
              score = question.lowScore.toFixed(1) + " /1"
              percent = (question.lowScore * 100).toFixed(1)
              attempts = question.numberOfAttempts
            }

            chartData.push(
              {
                name: question.questionName, score: score, percent: percent, attempts: attempts, school: selectedUserData.school, schoolStudentID: selectedUserData.schoolStudentID, email: selectedUserData.email,
              })
          }
        }
        // else if (getDataVisualSubset == "คะแนน Tag") {
        //   for (tag of getCourseQuizTagData) {
        //     tag.Qcorrect = 0;
        //     tag.Qwrong = 0;
        //     for (question of quizQuestionsList) {
        //       if (question.quizTagEnglish.length > 0) {
        //         for (var tag2 of question.quizTagEnglish) {
        //           if (tag2.id == tag.tagId) {
        //             tag.Qcorrect = question.Qcorrect + tag.Qcorrect;
        //             tag.Qwrong = question.Qwrong + tag.Qwrong;
        //           }
        //         }
        //       }
        //     }
        //   }

        //   for (item of getCourseQuizTagData) {
        //     if (format == "Percentage") {
        //       chartData.push(
        //         {
        //           name: item.tagName, correct: 100 * item.Qcorrect / (item.Qcorrect + item.Qwrong), wrong: 100 * item.Qwrong / (item.Qcorrect + item.Qwrong),
        //         })
        //     }
        //     else if (format == "Total") {
        //       chartData.push(
        //         {
        //           name: item.tagName, correct: item.Qcorrect, wrong: item.Qwrong,
        //         })
        //     }
        //   }
        // }
      }
      // ALl QUIZ ---------------------------------------------------------------------------------------------------------------------------------
      // ALl QUIZ ---------------------------------------------------------------------------------------------------------------------------------
      // ALl QUIZ ---------------------------------------------------------------------------------------------------------------------------------
      // ALl QUIZ ---------------------------------------------------------------------------------------------------------------------------------
      else {
        if (getDataVisualSubset == "คะแนนทั้งหมด") {

          console.log('tomori', selectedUserData)
          setColumns(columns5)

          for (var quizSet of getQuizList) {
            var quizQuestionsList = getCourseQuizQuestionData.filter((item) => item.mediaId == quizSet.mediaId)
            var userAttempts = quizAttemptListSorted.filter((item) => item.mediaId == quizSet.mediaId)

            i = 1;
            for (var quizAttempt of userAttempts[0]) {
              console.log('quizAttempt')
              console.log(quizAttempt)
              if (quizAttempt.passResult) {
                passResult = "PASS"
              } else {
                passResult = "FAIL"
              }
              var quizDate = new Date()
              quizDate.setTime(quizAttempt.endTime)


              chartData.push(
                {
                  name: quizSet.title,
                  score: quizAttempt.Qcorrect + "/" + (quizAttempt.Qcorrect + quizAttempt.Qwrong),
                  percent: (100 * quizAttempt.Qcorrect / (quizAttempt.Qcorrect + quizAttempt.Qwrong)).toFixed(1),
                  status: passResult,
                  date: quizDate.getDate() + "/" + quizDate.getMonth() + "/" + quizDate.getFullYear() + " " + quizDate.getHours() + ":" + ('0' + quizDate.getMinutes()).slice(-2),
                  school: selectedUserData.school, schoolStudentID: selectedUserData.schoolStudentID, email: selectedUserData.email,
                })

              i++;
            }
          }
        }
        else if (getDataVisualSubset == "คะแนนเฉลี่ย") {
          console.log('hiroshita')
          console.log(quizAttemptListSorted)

          setColumns(columns4)

          var runningScore = 0;
          var runningNumberOfQuestions = 0;
          var runningAttempts = 0;
          var runningPassResult = 0;
          for (i in quizList) {
            var score;
            var percent;
            var attempts;
            var passResult;
            if (quizAttemptListSorted[i].numberOfAttempts == 0) {
              passResult = "-"
              score = "-"
              percent = "-"
              attempts = "-"

            } else {
              passResult = "FAIL"
              score = quizAttemptListSorted[i].avgQcorrect.toFixed(1) + "/" + quizAttemptListSorted[i].numberOfQuestions.toString()
              percent = (100 * quizAttemptListSorted[i].totalCorrect / (quizAttemptListSorted[i].numberOfQuestions * quizAttemptListSorted[i].numberOfAttempts)).toFixed(1)
              attempts = quizAttemptListSorted[i].numberOfAttempts

              runningScore = runningScore + quizAttemptListSorted[i].avgQcorrect;
              runningNumberOfQuestions = runningNumberOfQuestions + quizAttemptListSorted[i].numberOfQuestions;
              runningAttempts = runningAttempts + quizAttemptListSorted[i].numberOfAttempts;


              for (var key in quizAttemptListSorted[i]) {
                if (quizAttemptListSorted[i].hasOwnProperty(key)) {
                  if (quizAttemptListSorted[i][key].passResult == true) {
                    passResult = "PASS"
                    runningPassResult = runningPassResult + 1;
                  }
                }
              }
            }

            chartData.push(
              {
                name: quizList[i].title, score: score, percent: percent, attempts: attempts, status: passResult, school: selectedUserData.school, schoolStudentID: selectedUserData.schoolStudentID, email: selectedUserData.email,
              })
          }
          // Summary
          chartData.push(
            {
              name: "สรุปทั้งหมด", score: runningScore.toFixed(1) + "/" + runningNumberOfQuestions, percent: (100 * runningScore / runningNumberOfQuestions).toFixed(1), attempts: runningAttempts + "/" + quizList.length, status: 100 * runningPassResult / quizList.length + "%", school: selectedUserData.school, schoolStudentID: selectedUserData.schoolStudentID, email: selectedUserData.email,
            })


        }
        else if (getDataVisualSubset == "คะแนนสูงสุด") {

          setColumns(columns4)


          var runningScore = 0;
          var runningNumberOfQuestions = 0;
          var runningAttempts = 0;
          var runningPassResult = 0;
          for (i in quizList) {
            var score;
            var percent;
            var attempts;
            var passResult;
            if (quizAttemptListSorted[i].numberOfAttempts == 0) {
              passResult = "-"
              score = "-"
              percent = "-"
              attempts = "-"

            } else {
              passResult = "FAIL"
              score = quizAttemptListSorted[i].highScore + "/" + quizAttemptListSorted[i].numberOfQuestions.toString()
              percent = (100 * quizAttemptListSorted[i].highScore / quizAttemptListSorted[i].numberOfQuestions).toFixed(1)
              attempts = quizAttemptListSorted[i].numberOfAttempts

              runningScore = runningScore + quizAttemptListSorted[i].highScore;
              runningNumberOfQuestions = runningNumberOfQuestions + quizAttemptListSorted[i].numberOfQuestions;
              runningAttempts = runningAttempts + quizAttemptListSorted[i].numberOfAttempts;


              for (var key in quizAttemptListSorted[i]) {
                if (quizAttemptListSorted[i].hasOwnProperty(key)) {
                  if (quizAttemptListSorted[i][key].passResult == true) {
                    passResult = "PASS"
                    runningPassResult = runningPassResult + 1;
                  }
                }
              }
            }

            chartData.push(
              {
                name: quizList[i].title, score: score, percent: percent, attempts: attempts, status: passResult, school: selectedUserData.school, schoolStudentID: selectedUserData.schoolStudentID, email: selectedUserData.email,
              })
          }
          // Summary
          chartData.push(
            {
              name: "สรุปทั้งหมด", score: runningScore + "/" + runningNumberOfQuestions, percent: (100 * runningScore / runningNumberOfQuestions).toFixed(1), attempts: runningAttempts + "/" + quizList.length, status: 100 * runningPassResult / quizList.length + "%", school: selectedUserData.school, schoolStudentID: selectedUserData.schoolStudentID, email: selectedUserData.email,
            })

        }
        else if (getDataVisualSubset == "คะแนนตํ่าสุด") {

          setColumns(columns4)

          var runningScore = 0;
          var runningNumberOfQuestions = 0;
          var runningAttempts = 0;
          var runningPassResult = 0;
          for (i in quizList) {
            var score;
            var percent;
            var attempts;
            var passResult;
            if (quizAttemptListSorted[i].numberOfAttempts == 0) {
              passResult = "-"
              score = "-"
              percent = "-"
              attempts = "-"

            } else {
              passResult = "FAIL"
              score = quizAttemptListSorted[i].lowScore.toString() + "/" + quizAttemptListSorted[i].numberOfQuestions.toString()
              percent = (100 * quizAttemptListSorted[i].lowScore / quizAttemptListSorted[i].numberOfQuestions).toFixed(1)
              attempts = quizAttemptListSorted[i].numberOfAttempts

              runningScore = runningScore + quizAttemptListSorted[i].lowScore;
              runningNumberOfQuestions = runningNumberOfQuestions + quizAttemptListSorted[i].numberOfQuestions;
              runningAttempts = runningAttempts + quizAttemptListSorted[i].numberOfAttempts;


              for (var key in quizAttemptListSorted[i]) {
                if (quizAttemptListSorted[i].hasOwnProperty(key)) {
                  if (quizAttemptListSorted[i][key].passResult == true) {
                    passResult = "PASS"
                    runningPassResult = runningPassResult + 1;
                  }
                }
              }
            }

            chartData.push(
              {
                name: quizList[i].title, score: score, percent: percent, attempts: attempts, status: passResult, school: selectedUserData.school, schoolStudentID: selectedUserData.schoolStudentID, email: selectedUserData.email,
              })
          }
          // Summary
          chartData.push(
            {
              name: "สรุปทั้งหมด", score: runningScore + "/" + runningNumberOfQuestions, percent: (100 * runningScore / runningNumberOfQuestions).toFixed(1), attempts: runningAttempts + "/" + quizList.length, status: 100 * runningPassResult / quizList.length + "%", school: selectedUserData.school, schoolStudentID: selectedUserData.schoolStudentID, email: selectedUserData.email,
            })


        }
        else if (getDataVisualSubset == "คะแนน Tag") {
          var tempCourseQuizData = getCourseQuizQuestionData
          var questionAttemptList = []

          var tempCourseQuizTagData = getCourseQuizTagData

          for (item of tempCourseQuizData) {
            item.questionAttempts = []
          }

          for (item of tempCourseQuizTagData) {
            item.Qcorrect = 0;
            item.Qwrong = 0;
          }
          for (index in quizAttemptListSorted) {

            for (var quizAttempt of quizAttemptListSorted[index]) {
              // console.log(quizAttempt.quizData)
              for (var key in quizAttempt.quizData) {
                if (quizAttempt.quizData.hasOwnProperty(key)) {
                  console.log(quizAttempt.quizData[key]);

                  var filtration = getCourseQuizQuestionData.findIndex((item) => item.questionId == key)
                  if (filtration > -1) {
                    tempCourseQuizData[filtration].questionAttempts.push(quizAttempt.quizData[key])
                  }
                }
              }
            }
          }

          i = 0;
          var j = 0;
          // loop to get each question
          for (item of tempCourseQuizData) {
            if (item.quizTagEnglish.length > 0) {
              item.Qcorrect = 0;
              item.Qwrong = 0;

              // loop to get each tag in question, for each tag push data
              for (var tag of item.quizTagEnglish) {
                // console.log(item.questionAttempts)

                var tagIndex = tempCourseQuizTagData.findIndex((list) => list.tagId == tag.id)

                if (tagIndex > -1) {

                  for (var question of item.questionAttempts) {
                    if (question.result) {
                      // item.Qcorrect = item.Qcorrect + 1;
                      tempCourseQuizTagData[tagIndex].Qcorrect = tempCourseQuizTagData[tagIndex].Qcorrect + 1;
                    } else {
                      tempCourseQuizTagData[tagIndex].Qwrong = tempCourseQuizTagData[tagIndex].Qwrong + 1;
                      // item.Qwrong = item.Qwrong + 1;
                    }

                  }
                }
              }
            }
            i++;
          }


          for (item of tempCourseQuizTagData) {
            chartData.push(
              {
                name: item.tagName, correct: item.Qcorrect, wrong: item.Qwrong,
              })
          }
        }
      }

      for (item of chartData) {
        if (isNaN(item.correct)) {
          item.correct = 0;
        }
        if (isNaN(item.wrong)) {
          item.wrong = 0;
        }
      }

      setChartKey1([{ dataKey: 'correct', fill: '#68E456' }])
      setChartKey2([{ dataKey: 'wrong', fill: '#FD6253' }])

      setChartData(chartData)
    }
  }











  const data = [
    {
      key: '1',
      name: 'John Brown',
      age: 32,
      address: 'New York No. 1 Lake Park',
      tags: ['nice', 'developer'],
    },
    {
      key: '2',
      name: 'Jim Green',
      age: 42,
      address: 'London No. 1 Lake Park',
      tags: ['loser'],
    },
    {
      key: '3',
      name: 'Joe Black',
      age: 32,
      address: 'Sidney No. 1 Lake Park',
      tags: ['cool', 'teacher'],
    },
  ];



  return (

    <div className=" h-full w-full flex flex-col items-center py-4 justify-start">
      <div className="w-10/12 rounded-lg text-center text-white py-2 text-2xl font-bold mb-8 md:mb-10 bg-orange-500">
        Dashboard
      </div>


      <div className="flex flex-row flex-wrap justify-around w-full">



        <div className="bg-gray-200 p-6 rounded-lg mb-6" style={{ height: "auto", maxHeight: "600px", minWidth: "400px", width: "70%", overflowY: "auto" }}>
          <div className="rounded-lg text-center text-white py-2 text-xl font-bold bg-purple-500 mx-auto" style={{ width: "120px", maxHeight: "500px" }}>
            Student
          </div>
          <div className="mt-4 flex flex-col">

            <div
              onClick={() => { setSelectedStudent("All"); setSelectedStudentIndex(-1) }}
              className="flex mt-4 bg-white  rounded-lg p-2 items-center"
              style={{ backgroundColor: (getSelectedStudentIndex == -1) ? "lightgray" : "white" }} >
              <div className="mr-4"> </div>
              <p> All Students</p>
            </div>

            {getUserDataPool.map((item, index) => {
              return (
                <div
                  onClick={() => { setSelectedStudent(item); setSelectedStudentIndex(index) }}
                  className="flex mt-4 bg-white  rounded-lg p-2 items-center"
                  key={index} style={{ backgroundColor: (getSelectedStudentIndex == index) ? "lightgray" : "white" }}>
                  <div className="mr-4"> </div>
                  <p>{(item.name) ? "Name: " + item.name : "undefined"} , Email:  {item.email} , School: {item.school} </p>
                  {/* <p>{(item.displayName) ? item.displayName : "undefined"} - {item.email} + {item.uid}</p> */}
                </div>
              )
            })}


          </div>
        </div>


        <div className="bg-gray-200 p-6 rounded-lg" style={{ height: "auto", width: "70%", minWidth: "400px" }}>
          <div className="rounded-lg text-center text-white py-2 text-xl font-bold bg-blue-500 mx-auto" style={{ width: "120px", maxHeight: "300px" }}>
            Statistic
          </div>






          {/* <AreaChart type={getDataVisualFormat} chartData={getChartData} chartDataKeyList={getChartDataKeyList} key1={getChartKey1} key2={getChartKey2} key3={getChartKey3} /> */}
          {console.log('cccolo')}
          {console.log(getSelectedStudentIndex)}
          {/* {((getSelectedStudentIndex != -2) && (getSelectedStudentIndex != -1)) ?
            <>
              <div className="mt-4" style={{ textAlign: "left" }}>
                CLASS COMPLETION 98%
              </div>
              <div className="mt-2" style={{ textAlign: "left" }}>
                QUIZ COMPLETION 98%
            </div>
              <div className="mt-2" style={{ textAlign: "left" }}>
                QUIZ SCORE 98%
            </div>
            </>
            : <></>} */}


          <div style={{ width: "100%", marginTop: "2vh", overflow: "hidden" }}>
            <div style={{ width: "50%", float: "left" }}>
              <div className="font-bold text mb-2"> เลือกการวิเคราะห์ผล </div>
              <Select
                defaultValue="ความคืบหน้า"
                onChange={e => setDataVisual(e)}
                value={getDataVisual}
                style={{ minWidth: "210px" }}
              >
                <Option value="ความคืบหน้า">ความคืบหน้า</Option>
                <Option value="ผลแบบฝึกหัด">ผลแบบฝึกหัด</Option>
              </Select>
            </div>

          </div>



          {(getDataVisual == "ผลแบบฝึกหัด") ?
            <div style={{ marginTop: "2vh" }}>
              {/* <Radio.Group onChange={(e) => setDataVisualFormat(e.target.value)} value={getDataVisualFormat}>
                <Radio value={"Total"}> Total </Radio>
                <Radio value={"Percentage"}> Percentage </Radio>

              </Radio.Group> */}


              Choose Quiz :
              <select
                defaultValue="All Quiz"
                onChange={e => setQuizFilter(e.target.value)}
                value={getQuizFilter}
                style={{ maxWidth: "100px", marginRight: "10px" }}
              >
                <option value={"All Quiz"}> All Quiz </option>;
                {getQuizList.map(item => {
                  return <option value={item.mediaId}> {item.title} </option>;
                })}
              </select>


              Filter Results :
              <select
                defaultValue="คะแนนทั้งหมด"
                onChange={e => setDataVisualSubset(e.target.value)}
                value={getDataVisualSubset}
                style={{ maxWidth: "100px", marginRight: "10px" }}
              >
                <option value="คะแนนเฉลี่ย">คะแนนเฉลี่ย</option>
                <option value="คะแนนสูงสุด">คะแนนสูงสุด</option>
                <option value="คะแนนตํ่าสุด">คะแนนตํ่าสุด</option>
                <option value="คะแนนทั้งหมด">คะแนนทั้งหมด</option>

                {/* {((getSelectedStudentIndex != -1)) ?
                  <>
                    <option value="คะแนนสูงสุด">คะแนนสูงสุด</option>
                    <option value="คะแนนตํ่าสุด">คะแนนตํ่าสุด</option>
                    <option value="คะแนนทั้งหมด">คะแนนทั้งหมด</option>
                  </>
                  : <></>} */}
              </select>



            </div>
            : <> </>}




          <div className="mt-4 flex flex-col" style={{ clear: "both", marginTop: "2vh", width: "100%", overflow: "auto" }}>


            {console.log('getChartData')}
            {console.log(getChartData)}
            {console.log(getColumns)}
            {console.log('getChartDataKeyList')}
            {console.log(getChartDataKeyList)}
            <p> {getFigureTitle} </p>
            <Table columns={getColumns} dataSource={getChartData} />

            {console.log('data columns', getColumns)}
            {console.log('data rows', getChartData)}
          </div>
          <div className="rounded-lg text-center text-white py-2 text-xs font-bold bg-gray-500 mx-auto" style={{ width: "120px", maxHeight: "200px", marginTop: "20px" }}>
            {/* <button onClick={downloadExcel}> Download Excel </button> */}
            <CsvDownload data={getChartData} />

          </div>

        </div>



      </div>
    </div>
  );
}
