import React, { useState, useEffect, useContext, useReducer } from "react";
import { Helmet } from "react-helmet";
import { Modal, Input, Select, Table, Avatar, TimePicker, Button, DatePicker } from "antd";
import moment from 'moment';
import Header from "../components/header/HeaderHome";

import { FaRegEdit } from "react-icons/fa";

import { getCoursePoolAction, CreateTeacherPaymentAction, getSchoolPoolAction, UpdateOrderHistoryAction, DeleteOrderHistoryAction, AddOrderHistoryAction } from "../actions";
import { GlobalContext } from "../hook/GlobalHook";
import AvatarSetting from "../components/settingContent/AvatarSetting";
import ProfileSetting from "../components/settingContent/ProfileSetting";
import PasswordSetting from "../components/settingContent/PasswordSetting";
import SaveBtnSetting from "../components/settingContent/SaveBtnSetting";
import RadarChart from "../components/settingContent/chart/RadarChart";
import MySchool from '../components/mySchool/MySchool'
import { GiConsoleController } from "react-icons/gi";
const { Option } = Select;
const { Column, ColumnGroup } = Table;




export default function OrderHistory() {
  const GlobalHook = useContext(GlobalContext);

  const [getUserOrderHistory, setUserOrderHistory] = useState([])

  const [getAllCourseData, setAllCourseData] = useState([])

  const [getDisplayOrderHistory, setDisplayOrderHistory] = useState([])
  const [getDisplayCurrentOrder, setDisplayCurrentOrder] = useState([])

  const [getDisplayPaymentModal, setDisplayPaymentModal] = useState(false)
  const [getModalItem, setModalItem] = useState(false)


  const [getPayerAccountInput, setPayerAccountInput] = useState(false)
  const [getPayAmmountInput, setPayAmmountInput] = useState(false)
  const [getPayTimeInput, setPayTimeInput] = useState(false)
  const [getPayDateInput, setPayDateInput] = useState(false)

  useEffect(() => {
    getCoursePoolAction(GlobalHook);
  }, [])

  useEffect(() => {
    if (GlobalHook.getGlobalUser) {
      console.log('G I JOE')
      console.log(GlobalHook.getGlobalUser)

      const orderHistory = GlobalHook.getGlobalUser.orderHistory;
      console.log(orderHistory)

      setUserOrderHistory(orderHistory)
    }
  }, [GlobalHook.getGlobalUser])

  useEffect(() => {
    if (GlobalHook.getGlobalCoursePool) {
      const courseData = GlobalHook.getGlobalCoursePool.filter((data) => data.coursePublic)
      console.log('courseData')
      console.log(courseData)
      setAllCourseData(courseData)
    }
  }, [GlobalHook.getGlobalCoursePool])

  useEffect(() => {
    var successList = []
    var failList = []
    for (var order of getUserOrderHistory) {
      for (var course of getAllCourseData) {
        if (order.courseId == course._id) {
          console.log('match found')
          console.log(order)

          var payment = ""
          var statusText = ""

          var price = 0;
          if (course.coursePrice == null) {
            price = "FREE"
          } else if (parseInt(course.coursePrice) == 0) {
            price = "FREE"
          } else {
            price = course.coursePrice;
          }


          // find if course is expired - 1 year
          const startDate = new Date(order.payment.date)
          const diffTime = Math.abs(Date.now() - startDate);
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          console.log('diffDays')
          console.log(diffDays)
          console.log(order.payment)


          if (price == "FREE") {
            statusText = "คอร์สพร้อมใช้งาน"
            payment = "NO"
          }
          else if (order.payment.status == "payment success") {
            if (diffDays > 366) {
              statusText = "คอร์สที่คุณสมัครหมดอายุครบเกิน 1 ปี"
              payment = "NO"
            } else {
              statusText = "คอร์สพร้อมใช้งาน"
              payment = "YES"
            }

          }
          else if (order.payment.status == "waiting confirmation") {
            statusText = "คุณได้ยื่นหลักฐานการชําระแล้ว โปรดรอให้เจ้าหน้าที่ยืนยัน"
            payment = "NO"
          }
          else if (order.payment.status == "waiting payment") {
            statusText = "คอร์สไม่พร้อมใช้งาน คุณยังไม่ได้ชําระ"
            payment = "NO"
          }

          if ((order.payment.status == "payment success") || (price == "FREE")) {
            successList.push({
              courseId: course._id,
              courseImage: course.courseImage,
              courseName: course.courseName,
              courseSlug: course.courseSlug,
              coursePrice: price,
              coursePaymentSuccess: payment,
              coursePayment: order.payment,
              statusText: statusText,
              status: order.payment.status,
              date: order.payment.date.slice(0, 10),
              diffDays: diffDays,
            })


          } else {
            failList.push({
              courseId: course._id,
              courseImage: course.courseImage,
              courseName: course.courseName,
              coursePrice: price,
              coursePaymentSuccess: payment,
              coursePayment: order.payment,
              statusText: statusText,
              status: order.payment.status,
              diffDays: diffDays,
            })
          }


        }
      }
    }
    setDisplayOrderHistory(successList)
    setDisplayCurrentOrder(failList)
  }, [getUserOrderHistory, getAllCourseData])


  return (
    <>

      <Header />

      <div className=" h-full w-full flex flex-col items-center py-4 justify-start">
        <div className="w-10/12 rounded-lg text-center text-white py-2 text-2xl font-bold mb-8 md:mb-10 bg-green-500">
          My Cart
        </div>
        <div className="flex flex-row flex-wrap justify-around w-full bg-white p-4">

          <div className="" style={{ width: "auto" }}>
            <div className="mb-4 font-semibold text-xl">
              Orders waiting for payment
            </div>

            <p style={{ fontSize: "18px", marginBottom: "10px" }}> สอบถามรายละเอียดหรือแจ้งเรื่องการชําระเงินได้ที่ เบอร์ 062-594-6255 (แพร) หรือ 087-585-9883 (หมิว) ได้เลย </p>

            <Table
              dataSource={getDisplayCurrentOrder}
              onRowClick={e => {
                console.log('row clicked')
              }}
            >
              <Column
                title="Course"
                dataIndex="courseImage"
                key="Profile"
                className="cursor-pointer"
                render={(text, record) => <Avatar size={60} src={record.courseImage} />}
              />

              <Column
                title="Name"
                dataIndex="courseName"
                key="Name"
                className="cursor-pointer"
              />

              <Column
                title="Price"
                dataIndex="coursePrice"
                key="Status"
                className="cursor-pointer"
              />

              <Column
                title="Paid"
                dataIndex="coursePaymentSuccess"
                key="Status"
                className="cursor-pointer"
              />


              <Column
                title="Action"
                key="action"
                render={(text, record) => {


                  if (record.status == "waiting payment") {
                    return (
                      <span>
                        <button
                          className="bg-red-500 rounded-lg p-4 hover:text-green-400"
                          style={{ marginRight: 16 }}
                          onClick={() => {
                            setModalItem(record)
                            setPayerAccountInput(record.coursePayment.payerAccount)
                            setPayAmmountInput(record.coursePayment.value)
                            // console.log('daterium-date')
                            // console.log(record.coursePayment.date.slice(0, 10))
                            // console.log('daterium-time')
                            // console.log(record.coursePayment.date.substring(11, 19))

                            console.log("gianfranco")
                            console.log(record);

                            if (record.coursePayment.date == null) {
                              console.log("zola");
                              var dateVar = new Date();
                              var dateString = dateVar.toDateString();
                              console.log(dateVar.getHours())
                              console.log(dateVar.getMinutes() )
                              console.log(dateVar.getSeconds() )
                              // setPayTimeInput( dateVar.getHours() + ":" + dateVar.getMinutes() + ":" + dateVar.getSeconds() )
                              // setPayDateInput(dateVar.getUTCFullYear() + "-" + (dateVar.getUTCMonth()+1).toString() + "-" + dateVar.getUTCDate() )
                            } else {
                              console.log("haaland")
                              var dateVar = new Date(record.coursePayment.date)
                              setPayTimeInput( dateVar.getHours() + ":" + dateVar.getMinutes() + ":" + dateVar.getSeconds() )
                              setPayDateInput(dateVar.getUTCFullYear() + "-" + (dateVar.getUTCMonth()+1).toString() + "-" + dateVar.getUTCDate() )
 
                              //setPayTimeInput(record.coursePayment.date.substring(11, 19))
                              //setPayDateInput(record.coursePayment.date.slice(0, 10))
                            }

                            setDisplayPaymentModal(true);
                          }}
                        >
                          โอนตอนนี้
                    </button>
                      </span>
                    )
                  }
                  else if (record.status == "waiting confirmation") {
                    return (
                      <span>
                        <button
                          className="bg-yellow-500 rounded-lg p-4 hover:text-green-400"
                          style={{ marginRight: 16, float: "left" }}
                          onClick={() => {
                            setModalItem(record)
                            setPayerAccountInput(record.coursePayment.payerAccount)
                            setPayAmmountInput(record.coursePayment.value)

                            if (record.coursePayment.date == null) {
                              console.log("zola");
                              var dateVar = new Date();
                              var dateString = dateVar.toDateString();
                              console.log(dateVar.getHours())
                              console.log(dateVar.getMinutes() )
                              console.log(dateVar.getSeconds() )
                              // setPayTimeInput( dateVar.getHours() + ":" + dateVar.getMinutes() + ":" + dateVar.getSeconds() )
                              // setPayDateInput(dateVar.getUTCFullYear() + "-" + (dateVar.getUTCMonth()+1).toString() + "-" + dateVar.getUTCDate() )
                            } else {
                              console.log("haaland")
                              var dateVar = new Date(record.coursePayment.date)
                              setPayTimeInput( dateVar.getHours() + ":" + dateVar.getMinutes() + ":" + dateVar.getSeconds() )
                              setPayDateInput(dateVar.getUTCFullYear() + "-" + (dateVar.getUTCMonth()+1).toString() + "-" + dateVar.getUTCDate() )
 
                              // setPayTimeInput(record.coursePayment.date.substring(11, 19))
                              // setPayDateInput(record.coursePayment.date.slice(0, 10))
                            }
                            
                            setDisplayPaymentModal(true);
                          }}
                        >
                          รอการยืนยัน
                    </button>
                        {/* <FaRegEdit style={{float:"left", marginTop:"20px"}}></FaRegEdit> */}
                      </span>
                    )
                  }
                  else if (record.status == "payment success") {

                      return (
                        <span>
                          <button
                            className="bg-green-500 rounded-lg p-4 hover:text-green-400"
                            style={{ marginRight: 16, float: "left" }}
                            disabled={true}
                          >
                            ชําระสําเร็จ monkey
                      </button>
                          {/* <FaRegEdit style={{float:"left", marginTop:"20px"}}></FaRegEdit> */}
                        </span>
                      )
                    



                  }
                }}
              />

              <Column
                title="Status"
                dataIndex="statusText"
                key="Status"
                className="cursor-pointer"
              />

              <Column
                title="Action"
                key="action"
                render={(text, record) => (
                  <span>
                    <a className="text-red-500 hover:text-red-400"
                      onClick={() => {
                        var payment = {
                          success: false,
                          date: new Date(getPayDateInput + 'T' + getPayTimeInput),
                          value: getPayAmmountInput,
                          payerAccount: getPayerAccountInput,
                          status: "waiting confirmation"
                        }

                        console.log('deleteClick')
                        console.log(record)
                        console.log(GlobalHook.getGlobalUser)
                        DeleteOrderHistoryAction(GlobalHook, GlobalHook.getGlobalUser, record.courseId, payment);
                      }}
                    >
                      ยกเลิก
                    </a>
                  </span>
                )}
              />
            </Table>
          </div>








          <div className="" style={{ width: "auto" }}>
            <div className="mb-4 font-semibold text-xl">
              Successful Orders
            </div>
            <Table
              dataSource={getDisplayOrderHistory}
              onRowClick={e => {
                console.log('row clicked')
              }}
            >
              <Column
                title="Course"
                dataIndex="courseImage"
                key="Profile"
                className="cursor-pointer"
                render={(text, record) => <Avatar size={60} src={record.courseImage} />}
              />

              <Column
                title="Name"
                dataIndex="courseName"
                key="Name"
                className="cursor-pointer"
              />

              <Column
                title="Price"
                dataIndex="coursePrice"
                key="Status"
                className="cursor-pointer"
              />

              <Column
                title="Paid"
                dataIndex="coursePaymentSuccess"
                key="Status"
                className="cursor-pointer"
              />


              <Column
                title="Action"
                key="action"
                render={(text, record) => {
                  if (record.coursePrice == "FREE") {
                    return (
                      <span>
                        <button
                          className="bg-gray-500 rounded-lg p-4 hover:text-green-400"
                          style={{ marginRight: 16 }}
                          disabled={true}
                        >
                          ไม่ต้องชําระ
                    </button>
                      </span>
                    )
                  }
                  else if (record.status == "waiting payment") {
                    return (
                      <span>
                        <button
                          className="bg-red-500 rounded-lg p-4 hover:text-green-400"
                          style={{ marginRight: 16 }}
                          onClick={() => {
                            setModalItem(record)
                            setPayerAccountInput(record.coursePayment.payerAccount)
                            setPayAmmountInput(record.coursePayment.value)
                            console.log('daterium-tt')
                            console.log(record.coursePayment.date.slice(0, 10))
                            console.log(record.coursePayment.date.substring(11, 19))

                            setPayTimeInput(record.coursePayment.date.substring(11, 19))
                            setPayDateInput(record.coursePayment.date.slice(0, 10))
                            setDisplayPaymentModal(true);
                          }}
                        >
                          โอนตอนนี้
                    </button>
                      </span>
                    )
                  }
                  else if (record.status == "waiting confirmation") {
                    return (
                      <span>
                        <button
                          className="bg-yellow-500 rounded-lg p-4 hover:text-green-400"
                          style={{ marginRight: 16, float: "left" }}
                          onClick={() => {
                            setModalItem(record)
                            setPayerAccountInput(record.coursePayment.payerAccount)
                            setPayAmmountInput(record.coursePayment.value)
                            console.log('daterium-oo')
                            console.log(record.coursePayment.date.slice(0, 10))
                            console.log(record.coursePayment.date.substring(11, 19))

                            setPayTimeInput(record.coursePayment.date.substring(11, 19))
                            setPayDateInput(record.coursePayment.date.slice(0, 10))
                            setDisplayPaymentModal(true);
                          }}
                        >
                          รอการยืนยัน
                    </button>
                        {/* <FaRegEdit style={{float:"left", marginTop:"20px"}}></FaRegEdit> */}
                      </span>
                    )
                  }
                  else if (record.status == "payment success") {
                    if (record.diffDays > 366) {
                      return (
                        <span>
                          <button
                            className="bg-yellow-500 rounded-lg p-4 hover:text-green-400"
                            style={{ marginRight: 16, float: "left" }}
                            onClick={() => {
                              // setModalItem(record)
                              // setPayerAccountInput(record.coursePayment.payerAccount)
                              // setPayAmmountInput(record.coursePayment.value)
                              // console.log('daterium')
                              // console.log(record.coursePayment.date.slice(0, 10))
                              // console.log(record.coursePayment.date.substring(11, 19))
  
                              // setPayTimeInput(record.coursePayment.date.substring(11, 19))
                              // setPayDateInput(record.coursePayment.date.slice(0, 10))
                              // setDisplayPaymentModal(true);

                              AddOrderHistoryAction(GlobalHook, GlobalHook.getGlobalUser.uid, record.courseId)
                            }}
                          >
                            ต่ออายุคอร์ส 1 ปี
                      </button>
                          {/* <FaRegEdit style={{float:"left", marginTop:"20px"}}></FaRegEdit> */}
                        </span>
                      )
                    } else {
                      return (
                        <span>
                          <button
                            className="bg-green-500 rounded-lg p-4 hover:text-green-400"
                            style={{ marginRight: 16, float: "left" }}
                            disabled={true}
                          >
                            ชําระสําเร็จ
                      </button>
                          {/* <FaRegEdit style={{float:"left", marginTop:"20px"}}></FaRegEdit> */}
                        </span>
                      )
                    }


                  }
                }}
              />

              <Column
                title="Status"
                dataIndex="statusText"
                key="Status"
                className="cursor-pointer"
              />

              <Column
                title="Date"
                dataIndex="date"
                key="Status"
                className="cursor-pointer"
              />

              <Column
                title="Action"
                key="action"
                render={(text, record) => (
                  <button
                    className="bg-blue-500 rounded-lg p-4 hover:text-green-400"
                    style={{ marginRight: 16 }}
                    onClick={() => {
                      console.log(record)
                      window.location.href = `/course/${record.courseSlug}`;
                    }}
                  >
                    Go To Course
                  </button>
                )}
              />
            </Table>
          </div>






















          <Modal
            title="การชําระค่าคอร์ส"
            visible={getDisplayPaymentModal}
            onOk={() => { setDisplayPaymentModal(false) }}
            onCancel={() => { setDisplayPaymentModal(false) }}
            footer={[
              <Button key="back" onClick={() => { setDisplayPaymentModal(false) }}>
                Return
              </Button>,
              <Button
                onClick={() => {
                  // getModalItem;
                  var tempList = getDisplayOrderHistory;
                  // var filterRes = tempList.filter( item => item.courseName == getModalItem.courseName)
                  // filterRes[0].statusText = "กรุณารอซักครู่ เพื่อให้เจ้าหน้าที่ของเรายืนยันการชําระ";
                  setDisplayOrderHistory(tempList);
                  console.log('testDate')
                  console.log(getPayDateInput + 'T' + getPayTimeInput)
                  console.log(new Date(getPayDateInput + 'T' + getPayTimeInput))

                  var payment = {
                    success: false,
                    date: new Date(getPayDateInput + 'T' + getPayTimeInput),
                    value: getPayAmmountInput,
                    payerAccount: getPayerAccountInput,
                    status: "waiting confirmation"
                  }
                  console.log('paymentDate')
                  console.log(payment)
                  UpdateOrderHistoryAction(GlobalHook, GlobalHook.getGlobalUser, getModalItem.courseId, payment);
                  setDisplayPaymentModal(false);
                }}
                key="submit" type="primary" >
                Submit
              </Button>,
            ]}
          >
            <p style={{ fontSize: "18px", marginBottom: "10px" }}> สมัครคอร์สระยะเลา 1 ปี</p>

            <p style={{ fontSize: "18px", marginBottom: "10px" }}> เพื่อสมัครเป็นสมาชิกคอร์สเรียนและเข้าถึงเนื้อหา โปรดแสดงหลังฐานการโอนเงินข้างล่างนี้ เมื่อเรารับการยืนยันยอดชำระแล้ว คุณก็จะเข้าดูเนื้อหาทั้งหมดในคอร์สได้!</p>

            {console.log('modalItem')}
            {console.log(getModalItem)}
            <p  style={{ color:"#1E8449" ,fontSize: "18px", marginBottom: "10px" }}> {"โปรดชําระจํานวน " + getModalItem.coursePrice + " บาท "} </p>
            <p style={{ color:"#1E8449" ,fontSize: "18px", marginBottom: "20px" }}> {"ที่บัญชีธนาคารกสิกรไทย หมายเลข 009 2831 795 ชื่อบัญชี บริษัท โปรเจ็บเล็บ จํากัด"} </p>

            <div style={{ height: "3px", backgroundColor: "lightgray" }}></div>

            <p style={{ fontSize: "18px", marginTop: "20px", marginBottom: "10px" }}> {"เมื่อโอนสําเร็จแล้ว กรอกข้อมูลการโอนข้างล่างนี้ เพื่อการยืนยัน"} </p>

            <p style={{ fontSize: "18px", marginTop: "10px", marginBottom: "10px" }}> {"เลขที่บัญชีของคุณ ที่ใช้โอน"} </p>
            <form>
              <input style={{ height: "40px", backgroundColor: "lightgray" }} type="text" name="name" value={getPayerAccountInput} onChange={(event) => setPayerAccountInput(event.target.value)} />
            </form>

            <p style={{ fontSize: "18px", marginTop: "10px", marginBottom: "10px" }}> {"จํานวนที่โอน"} </p>
            <form>
              <input style={{ height: "40px", backgroundColor: "lightgray" }} type="text" name="name" value={getPayAmmountInput} onChange={(event) => setPayAmmountInput(event.target.value)} />
            </form>

            <p style={{ fontSize: "18px", marginTop: "10px", marginBottom: "10px" }}> {"วันที่โอนสําเร็จ"} </p>
            {/* <DatePicker defaultValue={moment(getPayDateInput, 'YYYY-MM-DD')} format={'YYYY-MM-DD'} onChange={(event) => { console.log('date picker'); console.log(event._i); setPayDateInput(event._i) }}/> */}
            <DatePicker defaultValue={moment(getPayDateInput, 'YYYY-MM-DD')} format={'YYYY-MM-DD'} onChange={(dates, dateString) => { console.log('date picker'); console.log(dates); console.log(dateString); setPayDateInput(dateString) }} />


            <p style={{ fontSize: "18px", marginTop: "10px", marginBottom: "10px" }}> {"เวลาที่โอนสําเร็จ"} </p>
            <TimePicker defaultValue={moment(getPayTimeInput, 'HH:mm:ss')} size="large" onChange={(dates, dateString) => { console.log('date picker'); console.log(dateString); setPayTimeInput(dateString) }} />


          </Modal>


        </div>
      </div>
    </>
  );
}
