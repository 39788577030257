import React, { useContext, useState, useEffect } from "react";
import {
  Input,
  Button,
  Icon,
  Modal,
  Tabs,
  Checkbox,
  Popover,
} from "antd";
import { FaFacebook, FaLine } from 'react-icons/fa'
import { GlobalContext } from "../../hook/GlobalHook";
import { LoginAction, SignUpAction, ResetPassAction, GetMongoUserByUidAction, UpdateUserAgreementByUidAction } from "../../actions";
import { set } from "mongoose";
const LogInModal = () => {
  const GlobalHook = useContext(GlobalContext);

  const [getUserEmail, setUserEmail] = useState("");
  const [getUserName, setUserName] = useState("");

  const [getUserPassword, setUserPassword] = useState("");
  const [getUserPassword2, setUserPassword2] = useState("");

  const [getTermsConditionsModal, setTermsConditionsModal] = useState(false)

  const [getUserUid, setUserUid] = useState("");


  const { TabPane } = Tabs;

  useEffect(() => {
    setUserEmail("")
    setUserName("")
    setUserPassword("")
    setUserPassword2("")
  }, []);

  useEffect(() => {
    console.log('getGlobalUser')
    console.log(GlobalHook.getGlobalUser)
    if (GlobalHook.getGlobalUser) {
      setUserUid(GlobalHook.getGlobalUser.uid)
      if (GlobalHook.getGlobalUser.signAgreement != true) {
        setTermsConditionsModal(true)
      }
    }

  }, [GlobalHook.getGlobalUser]);

  function handleLoginAction() {
    setUserEmail("")
    setUserName("")
    setUserPassword("")
    setUserPassword2("")
    LoginAction(GlobalHook, { email: getUserEmail, password: getUserPassword }, "userpass");

  }

  function handleSignupAction() {
    console.log('signUpAction')
    setUserEmail("")
    setUserName("")
    setUserPassword("")
    setUserPassword2("")
    SignUpAction(GlobalHook, {
      email: getUserEmail,
      password: getUserPassword,
      password2: getUserPassword2,
      name: getUserName
    });
  }

  function userTermsConditions() {
    setTermsConditionsModal(true)
  }

  return (
    <>
      <Modal
        visible={GlobalHook.getGlobalShowLoginModal}
        onOk={() => GlobalHook.setGlobalShowLoginModal(false)}
        onCancel={() => GlobalHook.setGlobalShowLoginModal(false)}
        footer={null}
        style={{ maxWidth: "300px" }}
      >
        <Tabs
          defaultActiveKey="Login"
          activeKey={GlobalHook.getGlobalLoginTab}
          onChange={e => GlobalHook.setGlobalLoginTab(e)}
          on
          style={{ maxWidth: "300px" }}
        >
          <TabPane tab="Login" key="Login" className="flex flex-col">
            <div
              className="flex flex-col"
              onKeyPress={event => {
                if (event.key === "Enter") {
                  handleLoginAction();
                }
              }}
            >
              <p style={{ fontSize: "10px", textAlign: "center", marginBottom: "8px" }}> เมื่อคุณเข้าสู่ระบบ คุณได้ตกลกทําตามข้อตกลง <a href='/termspolicy' style={{ textDecoration: "underline" }}> ผู้ใช้</a></p>
              <Input
                prefix={<Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />}
                placeholder="Email"
                className="mb-4"
                type="email"
                onChange={e => setUserEmail(e.target.value)}
              />
              <Input.Password
                prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
                type="password"
                placeholder="Password"
                className="mb-6"
                onChange={e => setUserPassword(e.target.value)}
              />
              <div className="mb-2">
                <Checkbox onChange={() => { }}>Remember me</Checkbox>
              </div>

              <Button
                type="primary"
                className="mb-4"
                onClick={() => handleLoginAction()}
              >
                Log in
            </Button>
              <div className="flex">
                {" "}
              Don't have an account?
              <div
                  className="ml-2 underline hover:text-blue-500 cursor-pointer"
                  onClick={() => GlobalHook.setGlobalLoginTab("Signup")}

                >
                  Sign up!
              </div>
              </div>
              <div className="flex mt-2">

                <div
                  className="hover:text-blue-500 cursor-pointer text-gray-700"
                  onClick={() => GlobalHook.setGlobalLoginTab("forgotPass")}

                >
                  Forgot password ?
              </div>
              </div>
              <div className="flex flex-col items-center mt-4">
                <div className="border-b-2 border-gray-200" style={{ minWidth: "100%" }}>

                </div>

                <div className="flex mt-2">
                  {/* <FaFacebook className="text-blue-600 hover:text-blue-500 cursor-pointer mr-4" style={{ fontSize: "35px" }}
                    onClick={() => {
                      LoginAction(GlobalHook, null, "facebook")
                    }} /> */}
                    <p style={{paddingTop:"10px", paddingRight:"10px"}}> เข้าระบบด้วยบัญชี Google </p>
                  <Icon type="google-circle" theme="filled" className="text-red-600  hover:text-red-500 cursor-pointer mr-4" style={{ fontSize: "35px" }}
                    onClick={() => {
                      LoginAction(GlobalHook, null, "google")
                    }} />
                  {/* <Icon type="twitter-circle" theme="filled" className="text-blue-400 hover:text-blue-300 cursor-pointer" style={{ fontSize: "35px" }}
                    onClick={() => {
                      LoginAction(GlobalHook, null, "twitter")
                    }} /> */}

                </div>

              </div>
            </div>
          </TabPane>
          <TabPane tab="Signup" key="Signup" className="flex flex-col">
            <div
              className="flex flex-col"
              onKeyPress={event => {
                if (event.key === "Enter") {
                  handleSignupAction();
                }
              }}
            >
              <p style={{ fontSize: "10px", textAlign: "center", marginBottom: "8px" }}> เมื่อคุณเข้าสู่ระบบ คุณได้ตกลกทําตามข้อตกลง <a href='/termspolicy' style={{ textDecoration: "underline" }}> ผู้ใช้</a></p>

              <Input
                prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
                placeholder="Username"
                className="mb-4"
                onChange={e => setUserName(e.target.value)}
              />
              <Input
                prefix={<Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />}
                placeholder="Email"
                className="mb-4"
                type="email"
                onChange={e => setUserEmail(e.target.value)}
              />
              <Input.Password
                prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
                type="password"
                placeholder="Password"
                className="mb-6"
                onChange={e => setUserPassword(e.target.value)}
              />
              <Input.Password
                prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
                type="password"
                placeholder="Confirm Password"
                className="mb-6"
                onChange={e => setUserPassword2(e.target.value)}
              />




              {/* <Popover
              placement="bottom"
                content={content}
                title="Title"
                trigger="click"
                visible={true}
                // onVisibleChange={this.handleVisibleChange}
              > */}
              <Button
                type="primary"
                className="mb-2"
                onClick={() => {
                  console.log('handlingSignUP');
                  // userTermsConditions();
                  handleSignupAction();
                }}
              >
                Sign up
                </Button>
              {/* </Popover> */}







              <div className="flex flex-col items-center mt-4">
                <div className="border-b-2 border-gray-200" style={{ minWidth: "100%" }}>

                </div>

                <div className="flex mt-2">
                  {/* <FaFacebook className="text-blue-600 hover:text-blue-500 cursor-pointer mr-4" style={{ fontSize: "35px" }} onClick={() => LoginAction(GlobalHook, null, "facebook")} /> */}
                  <p style={{paddingTop:"10px", paddingRight:"10px"}}> เข้าระบบด้วยบัญชี Google </p>
                  <Icon type="google-circle" theme="filled" className="text-red-600  hover:text-red-500 cursor-pointer mr-4" style={{ fontSize: "35px" }} onClick={() => LoginAction(GlobalHook, null, "google")} />
                  {/* <Icon type="twitter-circle" theme="filled" className="text-blue-400 hover:text-blue-300 cursor-pointer" style={{ fontSize: "35px" }} onClick={() => LoginAction(GlobalHook, null, "twitter")} /> */}

                </div>

              </div>
            </div>
          </TabPane>
          <TabPane key="forgotPass" className="flex flex-col">
            <div
              className="flex flex-col items-center"
              onKeyPress={event => {
                if (event.key === "Enter") {
                  ResetPassAction(GlobalHook, getUserEmail);
                }
              }}
            >

              <div className="text-center">Please enter your email to request a password reset.</div>
              <Input
                prefix={<Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />}
                placeholder="Email"
                className="mb-4 mt-4"
                type="email"
                onChange={e => setUserEmail(e.target.value)}
              />


              <Button
                type="primary"
                className="mb-2"
                onClick={() => { ResetPassAction(GlobalHook, getUserEmail) }}
              >
                Submit
            </Button>
              <div className="flex flex-col items-center mt-4">
                <div className="border-b-2 border-gray-200" style={{ minWidth: "100%" }}>

                </div>



              </div>
            </div>
          </TabPane>
        </Tabs>
      </Modal>

      <Modal
        visible={false}
        title="Title"
        // onOk={ () => {setTermsConditionsModal(false)}}
        // onCancel={() => {setTermsConditionsModal(false)}}
        footer={[
          // <Button key="back" onClick={() => {setTermsConditionsModal(false)}}>
          //   Cancel
          // </Button>,
          <Button key="submit" type="primary" onClick={() => { setTermsConditionsModal(false); UpdateUserAgreementByUidAction(getUserUid, true); }}>
            Agree
            </Button>,
        ]}
      >
        <p>Some contents...</p>
        <p>Some contents...</p>
        <p>Some contents...</p>
        <p>Some contents...</p>
        <p>Some contents...</p>
      </Modal>
    </>
  );
};

export default LogInModal;
