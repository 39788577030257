import React from 'react';
import LoginPopup from './LoginPopup'
import SearchPopup from './SearchPopup'
const Popup = () => {
    return (
        <>
        <LoginPopup/>
        <SearchPopup/>
     
            
        </>
    );
}

export default Popup;
