import React, { useState, useContext, useEffect } from "react";
import { Input } from "antd";
import { GlobalContext } from "../../hook/GlobalHook";

import {
  getUserFromFirebase,
  CreateTeacherPaymentAction,
  getSchoolPoolAction,
  UpdateFirebaseUser,
  UpdateUserInfoAction,
  GetMongoUserByUidAction,
} from "../../actions";

const ProfileSetting = () => {
  const GlobalHook = useContext(GlobalContext);
  const [getEmail, setEmail] = useState("");
  const [getName, setName] = useState("");
  const [getSchool, setSchool] = useState("");
  const [getSchoolStudentID, setSchoolStudentID] = useState("");

  useEffect(() => {
    if (GlobalHook.getGlobalCurrentUser) {
      setName(GlobalHook.getGlobalCurrentUser.displayName);

      setEmail(GlobalHook.getGlobalCurrentUser.email);

      console.log("user uid", GlobalHook.getGlobalCurrentUser.uid);
      GetMongoUserByUidAction(GlobalHook.getGlobalCurrentUser.uid).then(
        (user) => {
          console.log("user mongo", user);
          setSchool(user.school);
          setSchoolStudentID(user.schoolStudentID);
        }
      );
    }
  }, [GlobalHook.getGlobalCurrentUser]);

  function handleNameChange() {
    console.log("HandleNameChange");
    console.log(GlobalHook.getGlobalCurrentUser);
    const displayName = GlobalHook.getGlobalCurrentUser.displayName;
    const uid = GlobalHook.getGlobalCurrentUser.uid;
    UpdateFirebaseUser(uid, getName);

    UpdateUserInfoAction(
      GlobalHook,
      uid,
      getName,
      getSchool,
      getSchoolStudentID
    );
  }

  return (
    <div className="w-100 mt-6 flex flex-col items-center">
      <div className="flex flex-col text-center mb-2 mb-6">
        <div className="font-bold text-lg mb-2">ชื่อของคุณ</div>
        <Input value={getName} onChange={(e) => setName(e.target.value)} />
      </div>

      <div className="flex flex-col text-center mb-2 mb-6">
        <div className="font-bold text-lg mb-2">ชื่อโรงเรียนของคุณ</div>
        <Input value={getSchool} onChange={(e) => setSchool(e.target.value)} />
      </div>

      <div className="flex flex-col text-center mb-2 mb-6">
        <div className="font-bold text-lg mb-2">หมายเลขนักศึกษา</div>
        <Input
          value={getSchoolStudentID}
          onChange={(e) => setSchoolStudentID(e.target.value)}
        />

        <button
          onClick={handleNameChange}
          className="bg-blue-400 rounded p-2 mt-3"
        >
          {" "}
          บันทึกการเปลี่ยนแปลง{" "}
        </button>
      </div>

      <div style={{ height: "20px" }}> </div>
      <div className="flex flex-col text-center mb-2 mb-6">
        <div className="font-bold text-lg mb-2">Email</div>
        <Input disable={true} value={getEmail} />
      </div>
    </div>
  );
};

export default ProfileSetting;
