import React, { useState, useContext, useEffect } from 'react'
import ScrollContainer from 'react-indiana-drag-scroll'
import { Switch, Modal, Input, Select, Tooltip, Button, Table, Avatar } from 'antd'
import { GlobalContext } from '../../hook/GlobalHook'

import { FaEdit } from "react-icons/fa"

import CourseCard from '../courseCard/CourseCard'
import SchoolCard from "../courseCard/SchoolCard";

import { AdminLogin, UpdataCourseStatusAction, UpdateCourseTag, getSubjectCategories, getSchoolPoolAction, GetAllUsersFromDB, GetManyUsersFromFirebase, UpdateOrderHistoryAction, CourseSubscriptionAction, UpdateCourseSubscriptionAction, getWebVisit } from '../../actions'
import axios from "axios";

import SchoolAdmin from "./AdminSchool";
// import VisitsSchema from '../../../../backend/models/Visits'

const { Column, ColumnGroup } = Table;




export default function AdminCourse() {

    const GlobalHook = useContext(GlobalContext)

    const [getcourseMatchPool, setcourseMatchPool] = useState([])

    const [getSubjects, setSubjects] = useState([])
    const [getApprovedTags, setApprovedTags] = useState([])
    const [getUnapprovedTags, setUnapprovedTags] = useState([])
    const [getTagModalStatus, setTagModalStatus] = useState(false)


    // Tag Editing States
    const [getSelectedTag, setSelectedTag] = useState([])

    const [getSelectedTagId, setSelectedTagId] = useState("")
    const [getSelectedTagEnglish, setSelectedTagEnglish] = useState("")
    const [getTagDisableEnglish, setTagDisableEnglish] = useState(true)
    const [getSelectedTagThai, setSelectedTagThai] = useState("")
    const [getTagDisableThai, setTagDisableThai] = useState(true)
    const [getSelectedTagSubject, setSelectedTagSubject] = useState("")
    const [getTagDisableSubject, setTagDisableSubject] = useState(true)
    const [getSelectedTagApproval, setSelectedTagApproval] = useState(false)

    // Login to Admin Page States
    const [getUserEnteredPassword, setUserEnteredPassword] = useState("")
    const [getUserEnteredPasswordDisplay, setUserEnteredPasswordDisplay] = useState("")

    const [getLoginStatus, setLoginStatus] = useState(false)

    // School Management States
    const [getEditSchoolModal, setEditSchoolModal] = useState(false)
    const [getEditSchoolSelected, setEditSchoolSelected] = useState(false)
    const [getEditSchoolSelectedName, setEditSchoolSelectedName] = useState(false)
    const [getWaitPaymentGroup, setWaitPaymentGroup] = useState([])
    const [getSuccessPaymentGroup, setSuccessPaymentGroup] = useState([])

    const [getVisitsList, setVisitsList] = useState([])
    const [getUsersList, setUsersList] = useState([])

    var users;
    useEffect(() => {

        GetAllUsersFromDB().then( result => {
            console.log('ffd')
            console.log(result)
            setUsersList(result.user);
        })



        getWebVisit().then( result => {
            console.log('kikuman')
            console.log(result)
            setVisitsList(result);
        })

        axios
            .get(`/api/tag/getalltags/`)
            .then(res => {
                console.log('fetchy')
                console.log(res.data)

                setApprovedTags(res.data.filter(tag => tag.approval == true))
                setUnapprovedTags(res.data.filter(tag => tag.approval != true))

                getSubjectCategories()
                    .then(data => {
                        setSubjects(data)
                        GlobalHook.setGlobalCourseSubjectFilter("All Subjects");
                        GetAllUsersFromDB()
                            .then(data => {
                                console.log('booty me')
                                console.log(data)
                                var allDbUsers = data.user;

                                GetManyUsersFromFirebase(allDbUsers)
                                    .then(data => {
                                        console.log('fetch all firebase')
                                        console.log(data)

                                        var allFirebaseUsers = data;
                                        var waitPaymentGroup = []
                                        var successPaymentGroup = []
                                        for (var user of allDbUsers) {
                                            //console.log('usery')
                                            //console.log(user)
                                            if (user.orderHistory) {
                                                for (var order of user.orderHistory) {
                                                    console.log(order.payment.status)
                                                    if (order.payment.status == "payment success") {
                                                        console.log('true love found')
                                                        successPaymentGroup.push({
                                                            userFirebase: allFirebaseUsers.filter(fireUser => fireUser.uid == user.uid)[0],
                                                            userDb: user,
                                                            order: order,
                                                        })
                                                    }
                                                    if ( (order.payment.status == "no payment") || (order.payment.status == "waiting confirmation") ) {
                                                        console.log('watoo')
                                                        console.log(user)
                                                        waitPaymentGroup.push({
                                                            userFirebase: allFirebaseUsers.filter(fireUser => fireUser.uid == user.uid)[0],
                                                            userDb: user,
                                                            order: order,
                                                        })
                                                    }
                                                }
                                            }
                                        }
                                        setSuccessPaymentGroup(successPaymentGroup)
                                        setWaitPaymentGroup(waitPaymentGroup)

                                        console.log('success group')
                                        console.log(successPaymentGroup)
                                        console.log('waiting payment group')
                                        console.log(waitPaymentGroup)
                                        var myDate = new Date(waitPaymentGroup[0].order.payment.date)
                                        console.log( myDate  )
                                    })
                                    .catch(error => {
                                        console.log(error)
                                    })


                            })
                            .catch(error => {
                                console.log(error)
                            })
                    })
                    .catch(error => {
                        console.log(error)
                    })
            })
            .catch(err => console.log(err));
    }, [])

    useEffect(() => {
        if (GlobalHook.getGlobalCoursePool[0]) {

            const MatchPool = GlobalHook.getGlobalCoursePool.filter((data) => data.coursePublish)
            setcourseMatchPool(MatchPool)
        }
    }, [GlobalHook.getGlobalCoursePool])


    function handleTagClick(event) {
        console.log('tagClicked')
        console.log(event)
        setTagModalStatus(true)
        setSelectedTagId(event._id)
        setSelectedTagSubject(event.subject)
        setSelectedTagEnglish(event.english)
        setSelectedTagThai(event.thai)
        setSelectedTagApproval(event.approval)
    }

    function handleCloseModal() {
        setTagModalStatus(false)
    }


    function adminSucessfullySignedIn() {
        return (
            <div className="bg-blue-300 flex flex-col py-10 items-center" style={{ minHeight: "100vh" }}>


                <div className="bg-blue-500 w-3/4 rounded-lg text-center text-white py-2 text-2xl font-bold mb-6" >คอร์สทั้งหมด</div>
                <ScrollContainer hideScrollbars={false} vertical={false} className="flex-row overflow-x-auto flex md:flex-wrap md:overflow-hidden mt-10 w-4/5" >
                    {getcourseMatchPool.map((courseData, i) => <div key={i} className=" mb-4 mr-2 md:mr-0 hover:text-black curser-pointer no-underline md:w-1/3  lg:w-1/4 xl:w-1/4 flex justify-center flex-col" >

                        <div className="bg-white flex justify-center rounded-lg py-4 -mb-2" style={{ width: "200px" }} ><div className="text-bold mr-2">Active</div> <Switch defaultChecked={courseData.courseActive} checkedChildren="Yes" unCheckedChildren="No" onClick={(courseStatus) => UpdataCourseStatusAction(GlobalHook, courseData.courseSlug, courseStatus)} /></div>

                        <div onClick={() => window.location.href = `/teacher/${courseData.courseSlug}`}>
                            <CourseCard courseData={courseData} />
                        </div>
                    </div>)}
                </ScrollContainer>



                <div className="bg-blue-500 w-3/4 rounded-lg text-center text-white py-2 text-2xl font-bold mb-6 mt-6" style={{ backgroundColor: "#E67E22" }}> Tags Waiting For Approval </div>

                {console.log('yayo')}
                {console.log(getApprovedTags)}

                {getUnapprovedTags.map((tag) => (
                    <button
                        onClick={(e) => handleTagClick(tag)}
                        className="bg-gray-500 text-white p-2 rounded hover:bg-gray-400 mt-2"
                        style={{ backgroundColor: "#F4D03F" }}
                    >
                        {tag.english}
                    </button>

                    // <p style={{backgroundColor:("yellow"), paddingLeft:"10px", paddingRight:"10px", paddingTop:"10px", paddingBottom:"10px" }} onClick={(e) => handleTagClick(tag)}> {tag.english} </p>
                ))}

                <div className="bg-blue-500 w-3/4 rounded-lg text-center text-white py-2 text-2xl font-bold mb-6 mt-6" style={{ backgroundColor: "#1D8348" }}> Approved Tags </div>
                {getApprovedTags.map((tag) => (
                    <button
                        onClick={(e) => handleTagClick(tag)}
                        className="bg-gray-500 text-white p-2 rounded hover:bg-gray-400 mt-2"
                        style={{ backgroundColor: "#2ECC71" }}
                    >
                        {tag.english}
                    </button>
                ))}

                {console.log('loggy')}

                <Modal
                    title="Edit Knowledge Tag"
                    visible={getTagModalStatus}
                    onOk={() => { saveTagDataUpdate(); setTagModalStatus(!getTagModalStatus); }}
                    onCancel={() => { setTagModalStatus(!getTagModalStatus); setSelectedTagEnglish(""); }}
                >
                    <form >
                        <label> {"Tag ID: " + getSelectedTagId} </label>

                        <label style={{ display: "inline-block", marginTop: "10px" }}>
                            Tag English:
                        <input style={{ backgroundColor: "gray" }} type="text" name="namnme" onChange={(event) => setSelectedTagEnglish(event.target.value)} value={getSelectedTagEnglish} style={{ backgroundColor: "white" }} />
                        </label>

                        <label style={{ display: "inline-block", marginTop: "10px" }}>
                            Tag Thai:
                        <input style={{ backgroundColor: "gray" }} type="text" name="namnme" onChange={(event) => setSelectedTagThai(event.target.value)} value={getSelectedTagThai} style={{ backgroundColor: "white" }} />
                        </label>

                    </form>

                    <label for="subject" className="font-semibold mr-4">  {"Current Tag Subject : " + getSelectedTagSubject} </label>

                    {console.log("wtf")}
                    {console.log(getSelectedTagSubject)}


                    <select
                        style={{ marginTop: "10px" }}
                        onChange={e => { console.log('clickclack'); console.log(e.target.value); setSelectedTagSubject(e.target.value) }}>
                        {getSubjects.map(item => {
                            return <option value={item.english}> {item.thai}</option>;
                        })}
                    </select>



                    <Switch
                        defaultChecked={getSelectedTagApproval}
                        checkedChildren="Approved"
                        unCheckedChildren="Not Approved"
                        onClick={e => setSelectedTagApproval(e)}
                    />


                    <button onClick={deleteTagUpdate} className=" bg-red-500 rounded text-center mt-4 p-2 ml-2" > Delete Tag </button>

                </Modal>

                {console.log("getAllCourse")}
                {console.log(GlobalHook.getGlobalCoursePool)}
            </div>
        )
    }

    function deleteTagUpdate() {
        console.log('deleting tag')
        const pushData = {
            id: getSelectedTagId,
        };

        // delete Tag from Tag DB  
        axios
            .post(`/api/tag/deletetagbyid/`, pushData)
            .then(res => {
                console.log('deleteTagSuccess')
                console.log(res.data)

                for (var course of GlobalHook.getGlobalCoursePool) {
                    // var filter1 = course.courseTagEnglish.filter( tag => tag.id == getSelectedTagId)

                    var tagMatchFound = false;
                    for (var tagIndex in course.courseTagEnglish) {
                        if (course.courseTagEnglish[tagIndex].id == getSelectedTagId) {
                            console.log('matchFound');
                            console.log(course.courseTagEnglish)
                            console.log(tagIndex)
                            tagMatchFound = true;
                            break;
                        }
                    }

                    if (tagMatchFound == true) {
                        console.log('matchProcess')
                        var temp = course.courseTagEnglish;
                        console.log(temp)
                        course.courseTagEnglish.splice(tagIndex, 1)
                        course.courseTagThai.splice(tagIndex, 1)
                        console.log(course.courseTagEnglish)

                        UpdateCourseTag(course._id, course.courseTagEnglish, course.courseTagThai)
                    }
                }

                // Re-Fetch Tag Data In Page
                axios
                    .get(`/api/tag/getalltags/`)
                    .then(res => {
                        console.log('fetchy')
                        console.log(res.data)

                        setApprovedTags(res.data.filter(tag => tag.approval == true))
                        setUnapprovedTags(res.data.filter(tag => tag.approval != true))

                        setTagDisableThai(true); setTagDisableEnglish(true); setTagDisableSubject(true);

                        setTagModalStatus(false)
                    })
                    .catch(err => console.log(err));

            })
            .catch(err => console.log(err));

    }

    function updateTagsInCourseDB() {
        for (var course of GlobalHook.getGlobalCoursePool) {
            // var filter1 = course.courseTagEnglish.filter( tag => tag.id == getSelectedTagId)

            var tagMatchFound = false;
            for (var tagIndex in course.courseTagEnglish) {
                if (course.courseTagEnglish[tagIndex].id == getSelectedTagId) {
                    console.log('matchFound');
                    console.log(course.courseTagEnglish)
                    console.log(tagIndex)
                    tagMatchFound = true;
                    break;
                }
            }

            if (tagMatchFound == true) {
                console.log('matchProcess')
                course.courseTagEnglish[tagIndex].name = getSelectedTagSubject + ": " + getSelectedTagEnglish;
                course.courseTagEnglish[tagIndex].subject = getSelectedTagSubject;
                course.courseTagEnglish[tagIndex].approval = getSelectedTagApproval;


                var filterSubject = getSubjects.filter(subject => subject.english == getSelectedTagSubject)

                course.courseTagThai[tagIndex].name = filterSubject[0].thai + ": " + getSelectedTagThai;
                course.courseTagThai[tagIndex].subject = getSelectedTagSubject;
                course.courseTagThai[tagIndex].approval = getSelectedTagApproval;

                UpdateCourseTag(course._id, course.courseTagEnglish, course.courseTagThai)
            }
        }
    }


    function saveTagDataUpdate() {

        const pushData = {
            id: getSelectedTagId,
            english: getSelectedTagEnglish,
            thai: getSelectedTagThai,
            subject: getSelectedTagSubject,
            approval: getSelectedTagApproval
        };

        // update Tag Data DB
        axios
            .post(`/api/tag/updatetagbyid/`, pushData)
            .then(res => {
                console.log('updateTagSuccess')
                console.log(res.data)

                updateTagsInCourseDB();



                // Re-fetch Data
                axios
                    .get(`/api/tag/getalltags/`)
                    .then(res => {
                        console.log('fetchy')
                        console.log(res.data)

                        setApprovedTags(res.data.filter(tag => tag.approval == true))
                        setUnapprovedTags(res.data.filter(tag => tag.approval != true))
                        setTagDisableThai(true); setTagDisableEnglish(true); setTagDisableSubject(true);
                        setTagModalStatus(false)
                    })
                    .catch(err => console.log(err));

            })
            .catch(err => console.log(err));

    }



    function handlePasswordSubmit() {
        console.log('submit password')
        AdminLogin(getUserEnteredPassword).then( res => {

            console.log('loginResult')
            console.log(res)
            if ( res == true) {
                setLoginStatus(true)
            }
        })
    }

    function loginToAdmin() {
        return (
            <div style={{ height: "85vh" }}>
                <div style={{ height: "40vh" }}> </div>
                {/* <div className="justify-center hidden md:flex text-base" style={{width:"50%"}} > */}
                <div style={{ width: "40%", marginLeft: "30%", marginRight: "30%", display: "float" }}>
                    <Input.Password
                        // prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
                        type="password"
                        placeholder="Password"

                        // className="mb-6"
                        onChange={e => setUserEnteredPassword(e.target.value)}
                    />
                    <button className="bg-green-500 rounded text-center p-2" style={{ width: "100%", marginTop: "10px" }} onClick={handlePasswordSubmit} > Enter </button>
                </div>

                <div style={{ width: "60%", marginLeft: "20%", marginRight: "20%" }}>
                </div>

                <div style={{ height: "40vh" }}> </div>
            </div>
        )
    }

    function processOrderHistory(type, courseId) {

        var filterResult = getcourseMatchPool.filter(course => course._id == courseId)

        if (filterResult.length > 0) {
            if (type == "courseName") {
                
                return filterResult[0].courseName
            }
            else if (type == "coursePrice") {
                if (filterResult[0].coursePrice) {
                    return filterResult[0].coursePrice
                } else {
                    return "FREE"
                }

            }
        } else {
            if (type == "courseName") {
                return "Not Found"
            }
            else if (type == "coursePrice") {
                return "Not Found"
            }            
        }


    }

    function userPayment() {
        return (
            <div className="bg-yellow-300 flex flex-col py-10 items-center" style={{ minHeight: "50vh" }}>

                <div className="bg-blue-400 w-3/4 rounded-lg text-center text-white py-2 text-2xl font-bold mb-6" >User Payment</div>


                <div className="" style={{ width: "auto" }}>
                    <div className="mb-4 font-semibold text-xl">
                        Orders waiting for payment
                    </div>
        
                    <Table
                        dataSource={getWaitPaymentGroup}
                        onRowClick={e => {
                            console.log('row clicked')
                        }}
                    >
                        <Column
                            title="User Name"
                            dataIndex="courseImage"
                            key="Profile"
                            className="cursor-pointer"
                            render={(text, record) => <p>{record.userFirebase.displayName}</p>}
                        />

                        <Column
                            title="User Email"
                            dataIndex="courseName"
                            key="Name"
                            className="cursor-pointer"
                            render={(text, record) => <p>{record.userFirebase.email}</p>}
                        />

                        <Column
                            title="Course"
                            dataIndex="coursePrice"
                            key="Status"
                            // render={(text, record) => <p>{getcourseMatchPool.filter(course => course._id == record.order.courseId)[0].courseName}</p>}
                            render={(text, record) => <p>{ "tasi"}</p>}
                        />

                        <Column
                            title="Price"
                            dataIndex="coursePrice"
                            key="Status"
                            // render={(text, record) => <p>{getcourseMatchPool.filter(course => course._id == record.order.courseId)[0].coursePrice}</p>}
                            render={(text, record) => <p>{ "tiso"}</p>}

                        />

                        <Column
                            title="Status"
                            dataIndex="coursePrice"
                            key="Status"
                            render={(text, record) => <p>{record.order.payment.status}</p>}
                        />

                        <Column
                            title="Account No."
                            dataIndex="coursePrice"
                            key="Status"
                            render={(text, record) => <p>{record.order.payment.payerAccount}</p>}
                        />

                        <Column
                            title="Paid Amount"
                            dataIndex="coursePrice"
                            key="Status"
                            render={(text, record) => <p>{record.order.payment.value}</p>}
                        />

                        <Column
                            title="Payment Date"
                            dataIndex="coursePrice"
                            key="Status"
                            render={(text, record) => <p>{ new Date(record.order.payment.date).toString() }</p>}
                        />

                        <Column
                            title="Confirm Payment"
                            dataIndex="coursePrice"
                            key="Status"
                            render={(text, record) => {
                                console.log('jaralis')
                                console.log(getcourseMatchPool.filter(course => course._id == record.order.courseId))
                                return (
                                    // <p> {record.order.courseId}</p>
                                    <Switch 
                                        onChange={() => {
                                            console.log('mightymouse')
                                            console.log(record.order.payment.value)
                                            console.log(record.order.payment.payerAccount)
                                            console.log(record.order.payment.date)
                                            const payment = {                        
                                                date: record.order.payment.date,
                                                value: record.order.payment.value,
                                                payerAccount: record.order.payment.payerAccount,
                                                status: "payment success"
                                              }
                                              UpdateOrderHistoryAction(GlobalHook, record.userDb, record.order.courseId, payment)
                                                .then((data) => {

                                                    var matchingCourseSubscr = record.userDb.courseSubscription.filter(course => course.courseId == record.order.courseId)
                                                    
                                                    console.log('matchingCourseSubscr')
                                                    console.log(record.userDb.courseSubscription)
                                                    console.log(record.order.courseId)
                                                    console.log(matchingCourseSubscr)
                                                    if (matchingCourseSubscr.length > 0) {
                                                        // Update course subscription, not add new
                                                        console.log('updateCourseSubsr')
                                                        UpdateCourseSubscriptionAction(GlobalHook, record.order.courseId, record.userDb.uid, record.order.payment.date)
                                                    } else {
                                                        console.log('newCourseSubsr')
                                                        CourseSubscriptionAction(GlobalHook, record.order.courseId, record.userDb.uid, record.order.payment.date)
                                                    }
                                                })
                                              // Course Subsription

                                              
                                        }} />
                                )
                            }}
                        />
                    </Table>
                </div>







                <div className="" style={{ width: "auto", marginTop:"40px" }}>
                    <div className="mb-4 font-semibold text-xl">
                        Successful Payment
                    </div>


                    <Table
                        dataSource={getSuccessPaymentGroup}
                        onRowClick={e => {
                            console.log('row clicked')
                            {console.log('shashu')}
                            // {console.log(getcourseMatchPool.filter(course => course._id == e.order.courseId)[0].courseName)}
                            // {console.log(getcourseMatchPool)}
                            // {console.log(e.order.courseId)}

                        }}
                    >
                        <Column
                            title="User Name"
                            dataIndex="courseImage"
                            key="Profile"
                            className="cursor-pointer"
                            render={(text, record) => <p>{record.userFirebase.displayName}</p>}
                        />

                        <Column
                            title="User Email"
                            dataIndex="courseName"
                            key="Name"
                            className="cursor-pointer"
                            render={(text, record) => <p>{record.userFirebase.email}</p>}
                        />

                        <Column
                            title="Course"
                            dataIndex="coursePrice"
                            key="Status"
                            // render={(text, record) => <p>{getcourseMatchPool.filter(course => course._id == record.order.courseId)[0].courseName}</p>}
                            render={(text, record) => processOrderHistory("courseName", record.order.courseId) }
                        />

                        <Column
                            title="Price"
                            dataIndex="coursePrice"
                            key="Status"
                            // render={(text, record) => <p>{ (getcourseMatchPool.filter(course => course._id == record.order.courseId)[0].coursePrice)? "yes" : "no"}</p>}
                            render={(text, record) => processOrderHistory("coursePrice", record.order.courseId) }


                        />


                        <Column
                            title="Status"
                            dataIndex="coursePrice"
                            key="Status"
                            render={(text, record) => <p>{record.order.payment.status}</p>}
                        />

                        <Column
                            title="Account No."
                            dataIndex="coursePrice"
                            key="Status"
                            render={(text, record) => <p>{record.order.payment.payerAccount}</p>}
                        />

                        <Column
                            title="Paid Amount"
                            dataIndex="coursePrice"
                            key="Status"
                            render={(text, record) => <p>{record.order.payment.value}</p>}
                        />

                        <Column
                            title="Payment Date"
                            dataIndex="coursePrice"
                            key="Status"
                            render={(text, record) => <p>{ new Date(record.order.payment.date).toString()}</p>}
                        />


                    </Table>
                </div>

            </div>
        )
    }

    function displayVisitors() {


        // console.log('visitList')
        // console.log(visitList)
        // console.log(visitList.length)


        var teacherVisits = getVisitsList.filter( item => item.url.includes("https://www.studysabai.com/teacher"))
        var homeVisits = getVisitsList.filter( item => item.url.includes("https://www.studysabai.com"))

        var adminVisits = getVisitsList.filter( item => item.url.includes("https://www.studysabai.com/admin"))
        var courseVisits = getVisitsList.filter( item => item.url.includes("https://www.studysabai.com/course"))

        return (
            <>
                <h1 style={{textAlign:"center", marginTop:"20px", marginBottom:"10px"}}> Website Traffic </h1>
                <p style={{textAlign:"center", marginBottom:"20px"}}> Number of Visits: {getVisitsList.length}</p>

                <h1 style={{textAlign:"center", marginTop:"20px", marginBottom:"10px"}}> Home Visits </h1>
                <p style={{textAlign:"center", marginBottom:"20px"}}> Number of Visits: {homeVisits.length}</p>
                <h1 style={{textAlign:"center", marginTop:"20px", marginBottom:"10px"}}> Course Visits </h1>
                <p style={{textAlign:"center", marginBottom:"20px"}}> Number of Visits: {courseVisits.length}</p>
                <h1 style={{textAlign:"center", marginTop:"20px", marginBottom:"10px"}}> Teacher Visits </h1>
                <p style={{textAlign:"center", marginBottom:"20px"}}> Number of Visits: {teacherVisits.length}</p>
                <h1 style={{textAlign:"center", marginTop:"20px", marginBottom:"10px"}}> Admin Visits </h1>
                <p style={{textAlign:"center", marginBottom:"20px"}}> Number of Visits: {adminVisits.length}</p>

                <h1 style={{textAlign:"center", marginTop:"20px", marginBottom:"10px"}}> Users Registered </h1>
                <p style={{textAlign:"center", marginBottom:"20px"}}> Number of Users: { getUsersList.length }</p>
                {console.log('visali')}
                
                {console.log(getUsersList)}
            </>
        )
    }

    return (
        <div>
            {(getLoginStatus) ?
                <>
                    {displayVisitors() }
                    {userPayment()}
                    <SchoolAdmin></SchoolAdmin>
                    {adminSucessfullySignedIn()}
                </>
                : loginToAdmin()}


        </div>

    )
}
