import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router";
import Header from "../components/header/HeaderStudio";
import SideBar from "../components/sideBar/SideBarStudio";
import StudioContent from "../components/studioContent/StudioContent";
import { getCourseContentAction, getCoursePoolAllAction } from "../actions";
import { GlobalContext } from "../hook/GlobalHook";

export default function Studio() {
  let { courseSlug } = useParams();

  const GlobalHook = useContext(GlobalContext);

  useEffect(() => {
    // console.log("jurassic")
    getCourseContentAction(GlobalHook, courseSlug);
    GlobalHook.setGlobalCourseSlug(courseSlug);
    getCoursePoolAllAction(GlobalHook);
    console.log("hellostudio")
    console.log(GlobalHook.getGlobalCoursePool)
  }, []);

  window.onbeforeunload = function () {
    if (GlobalHook.getMutantStatus) {
      return "You have unsaved changes.  Do you want to leave this page and lose your changes?";
    } else {
      return;
    }
  };

  function checkUserStatus() {
    var courseData = GlobalHook.getGlobalCoursePool.filter(item => item.courseName == GlobalHook.getGlobalCourseName)[0];
    var displayStudioStatus = false;

    if ((courseData != null) && (GlobalHook.getGlobalUser != null)) {
      console.log('pokemon')
      console.log(GlobalHook.getGlobalUser)
      console.log(GlobalHook.getGlobalUser._id);
      console.log(courseData.courseOwnerId)
      // uid=OmolJhwiB2WaEplgj7FbFIQb1WO2=pooh.e@projectlab.co.th
      // uid=8X2794mwfFOWnxu4R9gv4t3vCKm1=peam215@gmail.com
      // uid=Xj8JZFsYz4a2HuXa72CH5yzfIrK2=info@projectlab.co.th
      if ((courseData.courseOwnerId == GlobalHook.getGlobalUser._id) || (GlobalHook.getGlobalUser.uid == 'OmolJhwiB2WaEplgj7FbFIQb1WO2') || (GlobalHook.getGlobalUser.uid == '8X2794mwfFOWnxu4R9gv4t3vCKm1') || (GlobalHook.getGlobalUser.uid == 'Xj8JZFsYz4a2HuXa72CH5yzfIrK2')) {
        console.log('yeah!')
        displayStudioStatus = true;
      }
    }
    return displayStudioStatus;
  }

  function renderStudio() {

  }

  return (
    <div className="flex relative overflow-hidden bg-gray-100">
      {/* {console.log("studio")}
      {console.log(GlobalHook.getGlobalC)} */}

      <Helmet>
        <title>Studysabai:Studio::{GlobalHook.getGlobalCourseName}</title>
      </Helmet>
      <Header />

      {console.log("mamamia")}

      {checkUserStatus() ? 
            <>
            <SideBar />
            <StudioContent />
          </>
      
      : <></>}

    </div>
  );
}
