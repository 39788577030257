import React, { useState, useEffect, useContext, useRef } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router";

import Banner from "../components/banner/Banner";
import Header from "../components/header/HeaderTeacher";
import TeacherCourse from "../components/teacherCourse/TeacherCourse";
import { getCoursePoolAllAction } from "../actions";
import { GlobalContext } from "../hook/GlobalHook";
import Footer from "../components/footer/Footer";
import { ApiGatewayV2 } from "aws-sdk";

export default function Teacher() {
  const GlobalHook = useContext(GlobalContext);
  const fillerRef = useRef()
  const [getFillerHeight, setFillerHeight] = useState()
  useEffect(() => {
    getCoursePoolAllAction(GlobalHook);
  }, []);

  useEffect(() => {
    if (fillerRef.current) {
      console.log('setting filler')
      console.log(window.innerHeight)
      console.log(fillerRef.current.offsetTop)
      setFillerHeight(window.innerHeight - fillerRef.current.offsetTop)
    }
  }, [GlobalHook])


  return (
    <>
      <div>
        <Helmet>
          <title>Studysabai:Contact</title>
        </Helmet>

        <Header />



        <div className="bg-blue-300 flex flex-col py-10 items-center" >

          <div className="flex flex-row flex-wrap justify-center w-full    ">

            <div className="bg-blue-500 w-3/4 rounded-lg text-center text-white py-2 text-2xl font-bold mb-6" > Contact Us </div>
            <div className="bg-gray-200 p-6 rounded-lg mb-6" style={{ textAlign: "center", marginRight: "50px", marginLeft: "50px", height: "70vh", minWidth: "400px", width: "200px", overflowY: "auto" }}>

              <p style={{ fontSize: "20px" }}> พบกับเราได้ที่: </p>
              <p style={{ fontSize: "14px", marginTop: "20px" }}> 12/12 ถนนพัฒนาการ ซอย 51 </p>
              <p> เขตสวนหลวง </p>
              <p> กรุงเทพ </p>
              <p> ไปรศณีย์ 10250</p>
              <p style={{ fontSize: "20px", marginTop: "20px" }}> ติดต่อเราได้ทาง </p>
              <p style={{ fontSize: "14px", marginTop: "20px" }}> Email: info@projectlab.co.th </p>
              <p> โทรศัพท์: 062-594-6255</p>

              <p style={{ fontSize: "14px", marginTop: "20px", marginLeft: "20px", marginRight: "20px" }}> พวกเราทีมงาน Studysabai และบริษัท Project Lab ตั้งใจสร้างระบบและนําแนื้อหาความรู้ดีๆมาแบ่งปัน เพื่อให้การเรียนรู้นั้นง่ายสะดวกสบาย และให้พวกเราทุกคนสามารถเข้าถึงแ เรายินดีคุยกับคุณ หากมีเรื่องอะไรก็ตาม หรือข้อสงสัย คําถาม หรือข้อเสนอแนะ ข้อติชม สามารถมาหาหรือติดต่อเราะได้เลย</p>
            </div>

            <div className="bg-gray-200 p-6 rounded-lg mb-6" style={{ textAlign:"center", height: "70vh", minWidth: "400px", width: "200px", overflowY: "auto" }}>

              <p style={{ fontSize: "20px" }}> Find us at: </p>
              <p style={{ fontSize: "14px", marginTop: "20px" }}> 12/12 Phattakarn Road, Soi 51 </p>
              <p> Suanluang District </p>
              <p> Bangkok </p>
              <p> Postcode 10250</p>
              <p style={{ fontSize: "20px", marginTop: "20px" }}> Contact us at: </p>
              <p style={{ fontSize: "14px", marginTop: "20px" }}> Email: info@projectlab.co.th </p>
              <p> Telephone: 062-594-6255</p>

              <p style={{ fontSize: "14px", marginTop: "20px", marginLeft: "20px", marginRight: "20px" }}> The goal of our team at Studysabai and Project Lab Co. Ltd., is to help contribute the educational landscape in Thailand so that any eager students can access quality resources at reasonable and affordable prices at their fingertips. If you have any thoughst to share with us, any enquiries, questions or whatever! Please let us know. We will be very happy to talk to you. </p>

            </div>

          </div>




        </div>






        <Footer />
      </div>
    </>
  );
}
