import React, { Component } from "react";
import Script from "react-load-script";

import "./Checkout.css";

import { publicKey } from "../../confidential/keys";

let Omise;

export class Checkout extends Component {
  handleScriptLoad = () => {
//     Omise = window.Omise;

//     Omise.setPublicKey(publicKey);

// Omise.createSource('promptpay', {
//   "amount": 400000,
//   "currency": "THB"
// }, function(statusCode, response) {
//   console.log(response)
// });
    // OmiseCard.configure({
    //   publicKey,
    //   frameLabel: "Studysabai",
    //   submitLabel: "PAY NOW",
    //   currency: "thb"
    // });
  };

  // creditCardConfigure = () => {
  //   OmiseCard.configure({
  //     defaultPaymentMethod: "promptpay"
  //   });
  //   OmiseCard.configureButton("#credit-card");
  //   OmiseCard.attach();
  // };

  // omiseCardHandler = () => {
  //   const { createCreditCardCharge } = this.props;
  //   OmiseCard.open({
  //     frameDescription: this.props.courseId,
  //     amount: this.props.amount*100,
  //     onCreateTokenSuccess: token => {
  //       createCreditCardCharge(this.props.courseId, this.props.amount*100, token);
  //     },
  //     onFormClosed: () => {}
  //   });
  // };

  handleClick = e => {
    e.preventDefault();
  
      // this.creditCardConfigure();
      // this.omiseCardHandler();
    
      this.props.promptPayCharge(this.props.courseId, this.props.amount*100)
   
  };

  render() {

    return (
      <div className="own-form">
        <Script
          url="https://cdn.omise.co/omise.js"
          onLoad={this.handleScriptLoad}
        />

        <form>
          <button
            id="credit-card"
            className="bg-orange-300 text-white p-2 rounded hover:bg-orange-400"
            type="button"
         
            onClick={this.handleClick}
          >
            Pay with Prompt Pay
          </button>
        </form>
      </div>
    );
  }
}

export default Checkout;
